<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantSelect',
})
</script>
<template>
  <a-select v-model:value="tenantId" placeholder="请选择商家" allowClear showSearch>
    <a-select-option v-for="item in tenantPagedList.items" :key="item.id" :value="item.id">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>
<script lang="ts" name="TenantSelect" setup>
import { ref, onMounted, watch, watchEffect } from 'vue'
import { Tenant } from '@/services/admin/tenant/types'
import { PagedResult } from '@/services/types'
import * as service from '@/services/admin/tenant'
const props = withDefaults(
  defineProps<{
    value: string
  }>(),
  { value: '' }
)
const emits = defineEmits(['update:value'])
const tenantId = ref(props.value)
const tenantPagedList = ref<PagedResult<Tenant>>({ totalCount: 0, items: [] })
onMounted(async () => {
  const { value } = await service.getList({ maxResultCount: 100 })
  tenantPagedList.value = value
})

watch(tenantId, () => {
  emits('update:value', tenantId.value)
})
watchEffect(() => {
  tenantId.value = props.value
})
</script>
<style lang="less" scoped></style>
