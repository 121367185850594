<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'RoleList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <DataTable ref="dataTable" :columns="columns" :search-properties="searchProperties" :search-params="searchParams" :fetch="service.getList">
      <template #actions>
        <CreateButton @click="() => (isCreateVisible = true)" />
      </template>
      <template #default="{ column, record }">
        <template v-if="column.key === 'createdOn'">
          <DateTimeLabel :value="record.createdOn" />
        </template>
        <template v-if="column.key === 'modifiedOn'">
          <DateTimeLabel :value="record.modifiedOn" />
        </template>
        <template v-else-if="column.key === 'action'">
          <a-space>
            <a-button type="link" @click="onModify(record)">详情</a-button>
            <DeleteButton @delete="onDelete(record)" :loading="isDeleting && record.id == currentRecord.id" :disabled="record.isStatic" />
          </a-space>
        </template>
      </template>
    </DataTable>
  </a-flex>

  <RoleCreateDrawer v-model:visible="isCreateVisible" @submitted="dataTable?.refresh()" />
  <RoleUpdateDrawer v-if="currentRecord" v-model:visible="isModifyVisible" :value="currentRecord" @submitted="dataTable?.refresh()" />
</template>
<script lang="ts" name="RoleList" setup>
import { ref, reactive } from 'vue'
import { notification } from 'ant-design-vue'
import { RolePagedQueryInput, Role } from '@/services/admin/role/types'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import CreateButton from '@/components/Standards/CreateButton.vue'
import DeleteButton from '@/components/Standards/DeleteButton.vue'
import RoleCreateDrawer from './RoleCreateDrawer.vue'
import RoleUpdateDrawer from './RoleUpdateDrawer.vue'
import columns from './columns.json'
import searchProperties from './searchProperties.json'
import * as service from '@/services/admin/role'

const dataTable = ref<DataTableInstance | null>(null)
const searchParams = reactive<RolePagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})

const isCreateVisible = ref(false)
const isModifyVisible = ref(false)
const isDeleting = ref(false)
const currentRecord = ref<Role | null>(null)
const onModify = (role: Role) => {
  currentRecord.value = role
  isModifyVisible.value = true
}
const onDelete = async (role: Role) => {
  currentRecord.value = role
  isDeleting.value = true
  const { isSuccess } = await service.remove(role.id)
  isDeleting.value = false
  if (isSuccess) {
    notification.success({ message: '删除成功' })
    dataTable.value?.refresh()
  }
}
</script>
<style lang="less" scoped></style>
