<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserCreateDrawer',
})
</script>
<template>
  <a-drawer v-bind="$attrs" v-model:open="open" :title="`修改 ${user.userName}`">
    <a-form :model="formModel" labelAlign="right" :labelCol="{ span: 6 }">
      <a-form-item label="唯一标识">
        <span>{{ user.id }}</span>
      </a-form-item>
      <a-form-item label="用户名称">
        <span>{{ user.userName }}</span>
      </a-form-item>
      <a-form-item label="所属商家">
        <span>{{ user.tenant?.name ?? '-' }}</span>
      </a-form-item>
      <a-form-item label="登录密码">
        <a-popconfirm :disabled="loginUser.id == user.id" title="确定要重置此用户的密码吗？" @confirm="onReset">
          <a href="javascript:void(0)">
            <a-space><SwapOutlined />重置 </a-space>
          </a>
        </a-popconfirm>
      </a-form-item>
      <a-form-item label="是否启用">
        <a-switch v-model:checked="formModel.isActive" />
      </a-form-item>
      <a-form-item label="用户角色">
        <a-checkbox-group :options="roleOptions" v-model:value="userRoles" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-flex justify="end" gap="small">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :loading="isSubmitting" @click="onSubmit">修改</a-button>
      </a-flex>
    </template>
  </a-drawer>
</template>
<script lang="ts" name="UserCreateDrawer" setup>
import { ref, reactive, watch, computed, watchEffect, onMounted } from 'vue'
import { Modal, notification } from 'ant-design-vue'
import { SwapOutlined } from '@ant-design/icons-vue'
import { User, UserUpdateInput, UserWithRoles } from '@/services/admin/user/types'
import { useForm } from 'ant-design-vue/es/form'
import { USER_INFO } from '@/store/mutation-types'
import { LoginUser } from '@/services/auth/types'
import storage from '@/utils/Storage'
import { Role } from '@/services/admin/role/types'
import * as service from '@/services/admin/user'
import * as roleService from '@/services/admin/role'
const loginUser = ref(storage.get(USER_INFO) as LoginUser)
const props = defineProps<{
  visible: boolean
  value: User
}>()
const emits = defineEmits(['update:visible', 'update:value', 'submitted'])
const roles = ref<Role[]>([])
const user = ref<UserWithRoles>({ ...props.value, roles: [] })
const userRoles = ref<string[]>([])
const currentUserRoles = computed(() => {
  return user.value.roles.map((item) => item.id)
})
const open = ref<boolean>(props.visible)
const isSubmitting = ref(false)
const formModel = reactive<UserUpdateInput>(service.map(props.value))
const formRules = reactive({})
const form = useForm(formModel, formRules)
const roleOptions = computed(() => {
  return roles.value.map((role) => {
    return {
      label: role.displayName,
      value: role.id,
      disabled: role.isStatic
    }
  })
})

const modifyRoles = async () => {
  const removes = currentUserRoles.value.filter((item) => !userRoles.value.includes(item))
  const adds = userRoles.value.filter((item) => !currentUserRoles.value.includes(item))
  if (removes.length > 0) {
    removes.forEach(async (role) => {
      const { value } = await service.removeRole(user.value.id, role)
      if (value) {
        user.value = value
        console.debug(`${role}角色删除成功`)
      }
    })
  }
  if (adds.length > 0) {
    adds.forEach(async (role) => {
      const { value } = await service.addRole(user.value.id, role)
      if (value) {
        user.value = value
        console.debug(`${role}角色添加成功`)
      }
    })
  }
}

const isResting = ref(false)
const onReset = async () => {
  isResting.value = true
  const { value } = await service.resetPassword(props.value.id)
  isResting.value = false
  if (value) {
    Modal.confirm({ title: '重置成功', content: `密码已重置为：${value.newPassword}`, okText: '确定' })
  }
}
const onSubmit = async () => {
  try {
    await form.validate()
  } catch {
    return
  }

  isSubmitting.value = true
  await modifyRoles()
  const { isSuccess, value } = await service.update(props.value.id, formModel)
  isSubmitting.value = false
  if (isSuccess) {
    notification.success({ message: '修改成功' })
    user.value = value
    form.resetFields()
    open.value = false
    emits('submitted')
  }
}
const onCancel = () => {
  open.value = false
}

watch(
  () => props.visible,
  (val) => {
    open.value = val
  }
)
watch(
  () => open.value,
  (val) => {
    emits('update:visible', val)
  }
)
watchEffect(async () => {
  Object.assign(formModel, props.value)
  user.value = { ...props.value, roles: [] }
  userRoles.value = []
  const { value } = await service.get(props.value.id)
  if (value) {
    user.value = value
    userRoles.value = user.value.roles.map((r) => r.id)
  }
})
onMounted(async () => {
  const { value } = await roleService.getList({ maxResultCount: 100 })
  if (value) {
    value.items.sort((a, b) => (a.isStatic && b.isStatic ? 1 : a.isStatic ? 1 : -1))
    roles.value = value.items
  }
})
</script>
<style lang="less" scoped></style>
