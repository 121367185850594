import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult } from '@/services/types'
import { User,UserCreateInput, UserPagedQueryInput } from './types'

export const get = async (id: string): Promise<Result<User>> => {
  const url = baseURL + `/api/tenant/users/${id}`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (query: UserPagedQueryInput): Promise<Result<PagedResult<User>>> => {
  const url = baseURL + '/api/tenant/users'
  var { status, data } = await clinet.get(url, { params: query })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const create = async (input: UserCreateInput): Promise<Result<User>> => {
  const url = baseURL + '/api/tenant/users'
  var { status, data } = await clinet.post(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
