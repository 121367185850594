<template>
  <a-row>
    <a-col :span="15">
      <UserSelect v-model:value="userId" />
    </a-col>
    <a-col :span="9">
      <a-button type="link" @click="onCreateUser">新建用户</a-button>
    </a-col>
  </a-row>

  <a-modal v-model:visible="isVisibleCreate" title="新建用户" :confirmLoading="isUserCreating" @ok="createUser">
    <a-form :model="userFormModel" labelAlign="right" :labelCol="{ span: 6 }">
      <a-form-item label="用户名" name="userName" v-bind="userForm.validateInfos.userName" :rules="userFormRules.userName">
        <a-input v-model:value="userFormModel.userName" placeholder="请输入用户名" />
      </a-form-item>

      <a-form-item label="登录密码" name="password" v-bind="userForm.validateInfos.password" :rules="userFormRules.password">
        <a-input-password v-model:value="userFormModel.password" placeholder="请输入登录密码" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script lang="ts" setup>
import { ref, reactive, watch, watchEffect } from 'vue'
import UserSelect from '@/components/Standards/UserSelect.vue'
import { UserCreateInput } from '@/services/tenant/user/types'
import { useForm } from 'ant-design-vue/es/form'
import * as userService from '@/services/tenant/user'

const props = defineProps<{
  value: string
}>()
const emits = defineEmits(['update:value'])
const userId = ref<string>(props.value)
const isVisibleCreate = ref<boolean>(false)
const userFormModel = ref<UserCreateInput>({
  userName: '',
  password: ''
})
const userFormRules = reactive({
  userName: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { max: 32, message: '用户名不能超过32个字符', trigger: 'change' },
    { pattern: /^[a-zA-Z0-9_]+$/, message: '用户名只能包含字母、数字和下划线', trigger: 'change' }
  ],
  password: [
    { required: true, message: '请输入登录密码', trigger: 'blur' },
    { max: 32, message: '登录密码不能超过32个字符', trigger: 'change' }
  ]
})
const userForm = useForm(userFormModel, userFormRules)
const onCreateUser = () => {
  isVisibleCreate.value = true
}
const isUserCreating = ref(false)
const createUser = async () => {
  try {
    await userForm.validate()
  } catch {
    return
  }

  isUserCreating.value = true
  const { value } = await userService.create(userFormModel.value)
  isUserCreating.value = false
  if (value) {
    userId.value = value.id
    isVisibleCreate.value = false
  }
}
watch(userId, (val) => {
  emits('update:value', val)
})
watchEffect(() => (userId.value = props.value))
</script>
<style lang="less" scoped></style>
