import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { Tenant, TenantUpdateInput } from './types'

export const get = async (): Promise<Result<Tenant>> => {
  const url = baseURL + `/api/tenant`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const update = async (input: TenantUpdateInput): Promise<Result<Tenant>> => {
  const url = baseURL + `/api/tenant`
  var { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getSettings = async (): Promise<Result<Record<string, string | null>>> => {
  const url = baseURL + `/api/tenant/settings`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
