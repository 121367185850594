<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChannelAccountList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <DataTable ref="dataTable" :columns="columns" :search-properties="searchProperties" :search-params="searchParams" :fetch="fetchData">
      <template #default="{ column, record }">
        <template v-if="column.key === 'tenantId'">
          <span>{{ record.tenant?.name }}</span>
        </template>
        <template v-else-if="column.key === 'channelId'">
          <a-space>
            <span>{{ record.channel.name }}</span>
          </a-space>
        </template>
        <template v-else-if="column.key === 'ownerId'">
          <span>{{ record.owner.userName }}</span>
        </template>
        <template v-else-if="column.key === 'userName'">
          <a-popover>
            <template #content>
              <div>账号昵称：{{ record.name }}</div>
              <div>游戏账号：{{ record.userName }}</div>
            </template>
            <span>{{ record.userName }} </span>
          </a-popover>
        </template>
        <template v-else-if="column.key === 'status'">
          <ChannelAccountStatus :record="record" />
        </template>
        <template v-else-if="column.key === 'actualAmount'">
          <span> {{ `￥${record.actualAmount} / ${record.totalAmount}` }}</span>
        </template>
        <template v-else-if="column.key === 'payableAmount'">
          <span>{{ `￥${record.payableAmount}` }}</span>
        </template>
        <template v-else-if="column.key === 'createdOn'">
          <DateTimeLabel :value="record.createdOn" format="MM-DD HH:mm" />
        </template>
        <template v-else-if="column.key === 'isEnabled'">
          <BooleanLabel :value="record.isEnabled" />
        </template>
        <template v-else-if="column.key === 'action'">
          <a-space size="small">
            <DetailButton @click="onEdit(record)" />
            <DeleteButton :loading="currentRecord?.id == record.id" @delete="onDelete(record)" />
          </a-space>
        </template>
      </template>
    </DataTable>
  </a-flex>
</template>
<script lang="ts" name="ChannelAccountList" setup>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import { ChannelAccount, ChannelAccountPagedQueryInput } from '@/services/admin/channelAccount/types'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import DetailButton from '@/components/Standards/DetailButton.vue'
import DeleteButton from '@/components/Standards/DeleteButton.vue'
import columns from './columns.json'
import search from './searchProperties.json'
import * as service from '@/services/admin/channelAccount'
import router from '@/router'
import ChannelAccountStatus from '@/components/Standards/ChannelAccountStatus.vue'

const currentRecord = ref<ChannelAccount | null>(null)
const dataTable = ref<DataTableInstance | null>(null)
const searchProperties = reactive(search)
const searchParams = reactive<ChannelAccountPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})

const fetchData = (params) => {
  return service.getList(params)
}
const onDelete = async (record: ChannelAccount) => {
  currentRecord.value = record
  const { isSuccess } = await service.remove(record.id)
  currentRecord.value = null
  if (isSuccess) {
    notification.success({ message: '删除成功' })
    dataTable.value?.refresh()
  }
}
const onEdit = (record: ChannelAccount) => {
  router.push({ name: 'ChannelAccountEdit', params: { id: record.id } })
}
onMounted(() => {
  for (const key in service.Status) {
    if (Object.prototype.hasOwnProperty.call(service.Status, key)) {
      const item = service.Status[key]
      searchProperties.status.options.items.push(item)
    }
  }
})
</script>
<style lang="less" scoped></style>
