import { UserLayout } from '@/layouts'
import { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/user',
    name: 'user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/auth/Login.vue'),
        meta: { title: '用户登录' }
      },
      {
        path: 'modify-password',
        name: 'modify-password',
        component: () => import('@/views/auth/ModifyPassword.vue'),
        meta: { title: '修改密码' }
      }
    ]
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/layouts/BlankLayout.vue'),
    redirect: '/pay/order',
    children: [
      {
        path: 'order/:tenantId/:channelId',
        name: 'pay-order',
        component: () => import('@/views/pay/index.vue'),
        meta: { title: '支付订单' }
      }
    ]
  },
  {
    path: '/exception/500',
    name: 'error',
    component: () => import('@/views/exception/500.vue')
  },
  {
    path: '/exception/403',
    name: '403',
    component: () => import('@/views/exception/403.vue')
  },
  {
    path: '/exception/404',
    name: '404',
    component: () => import('@/views/exception/404.vue')
  },
  {
    path: '/:path(.*)',
    name: 'NoMatch',
    component: () => import('@/views/exception/404.vue')
  }
] as RouteRecordRaw[]
