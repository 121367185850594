<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'RoleModifyDrawer',
})
</script>
<template>
  <a-drawer :title="`编辑 ${value.displayName} 角色`" v-model:open="open">
    <a-form :model="formModel" :rules="formRules" labelAlign="right" :labelCol="{ span: 6 }">
      <a-form-item label="角色标识">
        <label>{{ value.id }}</label>
      </a-form-item>
      <a-form-item label="静态角色" name="isStatic">
        <BooleanLabel :value="value.isStatic" />
      </a-form-item>
      <a-divider />
      <a-form-item v-bind="form.validateInfos.displayName" label="角色名称" name="displayName">
        <a-input v-model:value="formModel.displayName" placeholder="请输入角色名称" />
      </a-form-item>
    </a-form>
    <a-divider>角色权限</a-divider>
    <a-flex :vertical="true" gap="middle">
      <RolePermissionGroup v-for="item in permissionDefinitions" :key="item.name" :definition="item" :disabledList="disabledList" :checkedList="rolePermission" @change="onPermissionChange" />
    </a-flex>

    <template #footer>
      <a-flex justify="end" gap="small">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :disabled="value.isStatic || submitDisabled" :loading="isModifying" @click="onSubmit">保存</a-button>
      </a-flex>
    </template>
  </a-drawer>
</template>
<script lang="ts" name="RoleModifyDrawer" setup>
import { Role, RoleUpdateInput } from '@/services/admin/role/types'
import { Form, notification } from 'ant-design-vue'
import { ref, reactive, watch, computed, onMounted, watchEffect } from 'vue'
import { shallowEqual } from '@/utils/util'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import { PermissionDefinition } from '@/services/admin/permissions/types'
import RolePermissionGroup from './RolePermissionGroup.vue'
import * as service from '@/services/admin/role'
import * as permissionService from '@/services/admin/permissions'

const props = defineProps<{
  visible: boolean
  value: Role
}>()
const emits = defineEmits(['update:visible', 'submitted'])
const useForm = Form.useForm
const map = (role: Role): RoleUpdateInput => {
  const { displayName } = role
  return { displayName }
}

const open = ref(props.visible)
const formModel = reactive<RoleUpdateInput>(map(props.value))
const formRules = reactive({
  displayName: [
    { required: true, message: '请输入角色名称', trigger: 'blur' },
    { max: 32, message: '角色名称不能超过32个字符', trigger: 'change' }
  ]
})
const form = useForm(formModel, formRules)

const permissionDefinitions = ref<PermissionDefinition[]>([])
const rolePermission = ref([])
const roleNewPermissions = ref([])
const permissions = ref([])
const allPermissions = computed(() => {
  const list = []
  for (const item of permissionDefinitions.value) {
    list.push(item.name)
    if (item.children) {
      list.push(...item.children.map((p) => p.name))
    }
  }
  return list
})
const disabledList = computed(() => {
  if (props.value.isStatic) {
    return allPermissions.value
  }
  const list = allPermissions.value.filter((p) => !permissions.value.includes(p))
  return list
})
const onPermissionChange = (list: string[], groupPermissions: string[]) => {
  roleNewPermissions.value = roleNewPermissions.value.filter((item) => !groupPermissions.includes(item))
  roleNewPermissions.value.push(...list)
}

const isModifying = ref(false)
const submitDisabled = computed(() => {
  if (shallowEqual(map(props.value), formModel) == false) return false
  return isRolePermissionsChanged.value === false
})
const isRolePermissionsChanged = computed(() => {
  if (rolePermission.value.length !== roleNewPermissions.value.length) return true
  const array1 = [...rolePermission.value].sort()
  const array2 = [...roleNewPermissions.value].sort()
  return array2.some((value, index) => value !== array1[index])
})
const modifyRolePermissions = async () => {
  if (isRolePermissionsChanged.value === false) return
  const { value } = await permissionService.setForRole({
    role: props.value.id,
    permissions: roleNewPermissions.value
  })
  if (value) {
    rolePermission.value = value.items
  }
}
const onSubmit = async () => {
  try {
    await form.validate()
  } catch {
    return
  }
  isModifying.value = true
  await modifyRolePermissions()
  const { isSuccess } = await service.update(props.value.id, formModel)
  isModifying.value = false
  if (isSuccess) {
    notification.success({ message: '修改成功' })
    emits('submitted')
    open.value = false
  }
}
const onCancel = () => {
  open.value = false
}

watch(
  () => props.visible,
  (val) => {
    open.value = val
  }
)
watch(
  () => open.value,
  (val) => {
    emits('update:visible', val)
  }
)
watch(
  () => props.value,
  (val) => {
    if (val) {
      Object.assign(formModel, map(val))
    }
  }
)
watchEffect(async () => {
  const rolePermissionResult = await permissionService.getListByRole(props.value.id)
  if (rolePermissionResult.isSuccess) {
    rolePermission.value = rolePermissionResult.value.items
    roleNewPermissions.value = [...rolePermission.value]
  }
})
onMounted(async () => {
  const definitionResult = await permissionService.getDefinition()
  if (definitionResult.isSuccess) {
    permissionDefinitions.value = definitionResult.value.items
  }

  const permissionsResult = await permissionService.getList()
  if (permissionsResult.isSuccess) {
    permissions.value = permissionsResult.value.items
  }
})
</script>
<style lang="less" scoped></style>
