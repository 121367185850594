<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChannelSelect',
})
</script>
<template>
  <a-select v-model:value="channelId" placeholder="请选择通道" allowClear showSearch>
    <a-select-option v-for="(item, key) in channelPagedList.items" :key="key" :value="item?.id">
      {{ item?.name }}
    </a-select-option>
  </a-select>
</template>
<script lang="ts" name="ChannelSelect" setup>
import { ref, computed, onMounted, watch, watchEffect } from 'vue'

import { PagedResult } from '@/services/types'
import { Channel } from '@/services/admin/channel/types'
import { Channel as TenantChannel } from '@/services/tenant/channel/types'
import { Channel as VerifierChannel } from '@/services/verifier/channel/types'
import storage from '@/utils/Storage'
import { USER_INFO } from '@/store/mutation-types'
import { LoginUser } from '@/services/auth/types'
import * as adminChannelService from '@/services/admin/channel'
import * as tenantChannelService from '@/services/tenant/channel'
import * as verifierChannelService from '@/services/verifier/channel'

const props = withDefaults(
  defineProps<{
    value: string
  }>(),
  { value: '' }
)
const emits = defineEmits(['update:value'])

const loginUser = ref<LoginUser>(storage.get(USER_INFO))
const isTenant = computed(() => {
  return loginUser.value.roles.find((x) => x.id === 'tenant')
})
const isVerifier = computed(() => {
  return loginUser.value.roles.find((x) => x.id === 'verifier')
})

const channelId = ref(props.value)
const channelPagedList = ref<PagedResult<Channel> | PagedResult<TenantChannel> | PagedResult<VerifierChannel>>({ totalCount: 0, items: [] })

const getChannelList = async () => {
  if (isTenant.value) {
    const { value } = await tenantChannelService.getList({ maxResultCount: 100 })
    return value
  } else if (isVerifier.value) {
    const { value } = await verifierChannelService.getList({ maxResultCount: 100 })
    return value
  }
  const { value } = await adminChannelService.getList({ maxResultCount: 100 })
  return value
}

onMounted(async () => {
  loginUser.value = storage.get(USER_INFO) as LoginUser
  channelPagedList.value = await getChannelList()
})

watch(channelId, () => {
  emits('update:value', channelId.value)
})
watchEffect(() => {
  channelId.value = props.value
})
</script>
<style lang="less" scoped></style>
