<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DetailButton',
})
</script>
<template>
  <a-button v-bind="$attrs" :type="type" :loading="loading">
    <FileSearchOutlined v-if="showIcon" />
    <span>详情</span>
  </a-button>
</template>
<script lang="ts" name="DetailButton" setup>
import { FileSearchOutlined } from '@ant-design/icons-vue'
defineProps({
  type: {
    type: String,
    default: 'link'
  },
  showIcon: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean || Object,
    default: false
  }
})
</script>
<style lang="less" scoped></style>
