<template>
  <a-row>
    <a-col :span="15">
      <a-select v-model:value="groupId" placeholder="请选择分组" :options="groups" :fieldNames="{ label: 'name', value: 'id' }" allowClear style="width: 100%" />
    </a-col>
    <a-col :span="9">
      <a-button type="link" @click="isVisibleCreate = true">新建分组</a-button>
    </a-col>
  </a-row>

  <a-modal v-model:open="isVisibleCreate" title="新建分组" :confirmLoading="isCreating" @ok="createGroup">
    <VerifierGroupCreateFrom ref="groupForm" v-model:loading="isCreating" />
  </a-modal>
</template>
<script lang="ts" setup>
import { ref, reactive, watch, onMounted, watchEffect } from 'vue'
import { VerifierGroup } from '@/services/tenant/verifierGroup/types'
import * as groupService from '@/services/tenant/verifierGroup'
import VerifierGroupCreateFrom from '../groups/VerifierGroupCreateFrom.vue'
const props = defineProps<{
  value: string | null
}>()
const emits = defineEmits<{
  (e: 'update:value', value: string | null): void
}>()

const groups = reactive<VerifierGroup[]>([])
const groupId = ref(props.value)

const groupForm = ref()
const isVisibleCreate = ref(false)
const isCreating = ref(false)
const createGroup = async () => {
  const group = await groupForm.value.create()
  groups.push(group)
  if (group) {
    groupId.value = group.id
    isVisibleCreate.value = false
  }
}

watch(groupId, (val) => {
  emits('update:value', val)
})
watchEffect(() => {
  groupId.value = props.value
})
onMounted(async () => {
  if (groups.length <= 0) {
    const { value } = await groupService.getList()
    if (value) {
      value.items.forEach((group) => {
        groups.push(group)
      })
    }
  }
})
</script>
<style lang="less" scoped></style>
