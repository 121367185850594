import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult } from '@/services/types'
import {
  ChannelDailyStatistic,
  ChannelDailyStatisticPagedQueryInput,
  TenantDailyStatistic,
  TenantDailyStatisticPagedQueryInput,
  VerifierDailyStatistic,
  VerifierDailyStatisticPagedQueryInput
} from './types'

export const getTenantStatistics = async (query: TenantDailyStatisticPagedQueryInput): Promise<Result<PagedResult<TenantDailyStatistic>>> => {
  const url = baseURL + '/api/tenant/statistics/tenants'
  var { status, data } = await clinet.get(url, { params: query })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getChannelStatistics = async (query: ChannelDailyStatisticPagedQueryInput): Promise<Result<PagedResult<ChannelDailyStatistic>>> => {
  const url = baseURL + '/api/tenant/statistics/channels'
  var { status, data } = await clinet.get(url, { params: query })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getVerifierStatistics = async (query: VerifierDailyStatisticPagedQueryInput): Promise<Result<PagedResult<VerifierDailyStatistic>>> => {
  const url = baseURL + '/api/tenant/statistics/verifiers'
  var { status, data } = await clinet.get(url, { params: query })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
