<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Exception403',
})
</script>
<template>
  <a-result status="403" title="403" sub-title="Sorry, you don't have access to this page.">
    <template #extra>
      <a-button v-if="user.roles && user.roles.length > 0" type="primary" @click="toHome"> Back Home </a-button>
      <a-button v-else type="primary" @click="toLogin">Back Login</a-button>
    </template>
  </a-result>
</template>

<script lang="ts" name="Exception403" setup>
import { LoginUser } from '@/services/auth/types'
import { USER_INFO } from '@/store/mutation-types'
import storage from '@/utils/Storage'
import { useRouter } from 'vue-router'
const router = useRouter()
const user = storage.get(USER_INFO) as LoginUser
const toHome = () => {
  router.push({ path: '/' })
}
const toLogin = () => {
  router.push({ path: '/user/login' })
}
</script>
