import axios, { AxiosResponse } from 'axios'
import { message } from 'ant-design-vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import storage from '@/utils/Storage'
import { globalLoading } from '@/store/reactiveState'
import emitter from '@/utils/eventBus'
import router from '@/router'
import { signOut } from '@/services/auth'

const ContentType = {
  urlencoded: 'application/x-www-form-urlencoded;charset=UTF-8',
  json: 'application/json',
  formData: 'multipart/form-data'
}

// 创建 axios 实例   withCredentials: true,
const baseService = axios.create({
  timeout: 60 * 1000,
  responseType: 'json',
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
})

// request interceptor
baseService.interceptors.request.use(
  (config) => {
    globalLoading.value = true
    const token = storage.get(ACCESS_TOKEN)
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    config.headers['Content-Type'] = ContentType[config.data instanceof FormData ? 'formData' : 'json']
    return config
  },
  (error) => {
    globalLoading.value = false
    return Promise.reject(error)
  }
)

baseService.interceptors.response.use(
  (res: AxiosResponse<any>) => {
    globalLoading.value = false
    return res
  },
  (error) => {
    console.error(error)
    globalLoading.value = false
    const errMsg = error.message
    const res = error.response
    // 有响应内容
    if (res) {
      const { data, status } = res
      const error = data?.error ?? data
      if (status === 401) {
        message.error('登录过期，请重新登陆！')
        signOut()
      } else if (status === 400) {
        message.error(error?.message || '请求数据失败，请重试！')
      } else if (status === 403) {
        message.error(error?.message || '没有访问权限！')
      } else if (status === 404) {
        message.error('未找到资源(404)！')
      } else if (status === 500) {
        message.error(error?.message || '服务器发生了一个错误，请重试！')
      } else {
        message.error(error?.message || '未知错误，请重试！')
      }
      return res
    }

    // 无响应内容
    let failMsg = '未知错误，请重试！'
    if (errMsg === 'Network Error') {
      failMsg = '网络错误，请检查网络！'
    } else if (errMsg) {
      failMsg = errMsg
    }
    message.error(failMsg)
    return { status: 0, data: { error: failMsg } }
  }
)

export default baseService
