import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult } from '@/services/types'
import { ChannelAccount, ChannelAccountPagedQueryInput, ChannelAccountSetPayableAmountInput, ChannelAccountUpdateInput, DepositRecord, DepositRecordPagedQueryInput } from './types'

export const map = (a: ChannelAccount): ChannelAccountUpdateInput => {
  return {
    isEnabled: a.isEnabled,
    name: a.name,
    password: a.password,
    userName: a.userName
  }
}

export const get = async (id: string): Promise<Result<ChannelAccount>> => {
  const url = baseURL + `/api/tenant/accounts/${id}`
  const { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (input: ChannelAccountPagedQueryInput): Promise<Result<PagedResult<ChannelAccount>>> => {
  const url = baseURL + '/api/tenant/accounts'
  const { status, data } = await clinet.get(url, { params: input })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const update = async (id: string, input: ChannelAccountUpdateInput): Promise<Result<ChannelAccount>> => {
  const url = baseURL + `/api/tenant/accounts/${id}`
  const { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail(data.error.message)
  return Results.ok(data)
}

export const remove = async (id: string): Promise<Result> => {
  const url = baseURL + `/api/tenant/accounts/${id}`
  const { status } = await clinet.delete(url)
  if (status != 204) return Results.fail()
  return Results.ok()
}

export const reset = async (id: string): Promise<Result<ChannelAccount>> => {
  const url = baseURL + `/api/tenant/accounts/${id}/reset`
  const { status, data } = await clinet.put(url)
  if (status != 200) return Results.fail(data.error.message)
  return Results.ok(data)
}

export const setPayableAmount = async (id: string, input: ChannelAccountSetPayableAmountInput): Promise<Result<ChannelAccount>> => {
  const url = baseURL + `/api/tenant/accounts/${id}/payable-amount`
  const { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail(data.error.message)
  return Results.ok(data)
}

export const getDepositRecordList = async (input: DepositRecordPagedQueryInput): Promise<Result<PagedResult<DepositRecord>>> => {
  const url = baseURL + '/api/tenant/accounts/deposit-records'
  const { status, data } = await clinet.get(url, { params: input })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
