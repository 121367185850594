<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantChannelAccountEdit',
})
</script>
<template>
  <a-alert v-if="account.unavailableReason" :message="account.unavailableReason" type="error" banner style="margin-bottom: 1rem" />
  <a-form :model="formModel" labelAlign="right" :labelCol="{ span: 6 }">
    <a-row>
      <a-col :md="12" :sm="24">
        <a-form-item label="当前状态">
          <a-tag :color="getStatusTagColor(account.status)">
            {{ Status[account.status].label }}
          </a-tag>
          <a-popconfirm v-if="account.status == Status.Unavailable.value" title="确定要重置此账号吗？" @confirm="onReset">
            <a-button type="link" :loading="isResting">
              <span>重置</span>
              <a-popover>
                <template #content>
                  <span>将账号重置为可用状态</span>
                </template>
                <QuestionCircleOutlined />
              </a-popover>
            </a-button>
          </a-popconfirm>
        </a-form-item>
        <a-form-item label="所属通道">
          <span>{{ account.channel.name }}</span>
        </a-form-item>
        <a-form-item label="支付方式">
          <a-tag :color="account.payMethod ? 'blue' : 'warning'">{{ account.payMethod?.name ?? '未指定' }}</a-tag>
        </a-form-item>
        <a-form-item label="唯一标识">
          <span>{{ account.id }}</span>
        </a-form-item>
        <a-form-item label="账号名称" v-bind="form.validateInfos.name" :rules="formRules.name">
          <a-input v-model:value="formModel.name" />
        </a-form-item>
        <a-form-item label="游戏账号" v-bind="form.validateInfos.userName" :rules="formRules.userName">
          <a-input v-model:value="formModel.userName" />
        </a-form-item>
        <a-form-item label="游戏密码" v-bind="form.validateInfos.password" :rules="formRules.password">
          <a-input-password v-model:value="formModel.password" />
        </a-form-item>
        <a-form-item label="是否启用">
          <a-switch v-model:checked="formModel.isEnabled" />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item label="可用额度">
          <span>{{ `￥${account.payableAmount} / ${account.payableAmount + account.actualAmount}` }}</span>
        </a-form-item>
        <a-form-item label="实付金额">
          <span> {{ `￥${account.actualAmount}` }}</span>
        </a-form-item>
        <a-form-item label="创建时间">
          <DateTimeLabel :value="account.createdOn" />
        </a-form-item>
        <a-form-item label="修改时间">
          <DateTimeLabel :value="account.modifiedOn" />
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
  <a-flex justify="flex-end">
    <a-space>
      <a-button type="primary" :loading="isSubmitting" @click="onSubmit">保存</a-button>
      <a-button @click="router.back">返回</a-button>
    </a-space>
  </a-flex>
</template>
<script lang="ts" name="TenantChannelAccountEdit" setup>
import { ChannelAccountUpdateInput } from '@/services/tenant/accounts/types'
import { useRouter } from 'vue-router'
import { QuestionCircleOutlined, MoneyCollectOutlined } from '@ant-design/icons-vue'
import { ref, reactive, watchEffect, onMounted, computed } from 'vue'
import { useForm } from 'ant-design-vue/es/form'
import { notification } from 'ant-design-vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import { Status, getStatusTagColor } from '@/services/admin/channelAccount'
import { ChannelAccountWithRecords } from '@/services/tenant/accounts/types'
import * as service from '@/services/tenant/accounts'
const props = defineProps<{
  value: ChannelAccountWithRecords
}>()
const emits = defineEmits(['update:value'])

const router = useRouter()
const account = ref<ChannelAccountWithRecords>(props.value)

const formModel = ref<ChannelAccountUpdateInput>(service.map(account.value))
const formRules = reactive({
  name: [
    { required: true, message: '请输入账号名称', trigger: 'blur' },
    { max: 64, message: '账号名称长度不能超过64个字符', trigger: 'change' }
  ],
  userName: [
    { required: true, message: '请输入游戏账号', trigger: 'blur' },
    { max: 64, message: '游戏账号长度不能超过64个字符', trigger: 'change' }
  ],
  password: [{ max: 64, message: '密码长度不能超过64个字符', trigger: 'change' }]
})

const form = useForm(formModel, formRules)

const isResting = ref(false)
const onReset = async () => {
  isResting.value = true
  const { value } = await service.reset(account.value.id)
  isResting.value = false
  if (value) {
    account.value = value
    emits('update:value', value)
  }
}

const isSubmitting = ref(false)
const onSubmit = async () => {
  try {
    await form.validate()
  } catch {
    return
  }

  isSubmitting.value = true
  const { value } = await service.update(account.value.id, formModel.value)
  isSubmitting.value = false
  if (value) {
    notification.success({ message: '修改成功' })
    formModel.value = service.map(value)
    router.push({ name: 'TenantChannelAccountList' })
    emits('update:value', value)
  }
}

watchEffect(() => {
  account.value = props.value
  formModel.value = service.map(account.value)
})
</script>
<style lang="less" scoped></style>
