<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DataTableSearchForm',
})
</script>
<template>
  <a-form :model="value">
    <a-row :gutter="[16, 16]">
      <template v-for="(item, key, index) in properties" :key="key">
        <a-col :md="8" :sm="24" v-if="index < 3 || isExpand">
          <a-form-item :label="item.label" :name="key">
            <a-input
              v-if="item.type === 'input'"
              v-model:value="value[key]"
              :placeholder="item.options?.placeholder"
              :allowClear="item.options?.allowClear ?? true"
              :disabled="item.disabled"
              @change="onTriggerEvent($event, item.events?.change)"
            />

            <a-input-number
              v-if="item.type === 'number'"
              v-model:value="value[key]"
              :placeholder="item.options?.placeholder"
              :allowClear="item.options?.allowClear ?? true"
              :disabled="item.disabled"
              @change="onTriggerEvent($event, item.events?.change)"
              style="width: 100%;"
            />

            <a-select
              v-else-if="item.type === 'select'"
              v-model:value="value[key]"
              :placeholder="item.options?.placeholder"
              :allowClear="item.options?.allowClear ?? true"
              :showSearch="item.options?.showSearch ?? false"
              :disabled="item.disabled"
              @change="onTriggerEvent($event, item.events?.change)"
            >
              <a-select-option v-for="(option, index) in item.options.items" :key="index" :value="option.value">
                {{ option.label }}
              </a-select-option>
            </a-select>

            <UserSelect v-else-if="item.type === 'user'" v-model:value="value[key]" />

            <TenantSelect v-else-if="item.type === 'tenant'" v-model:value="value[key]" />

            <ChannelSelect v-else-if="item.type === 'channel'" v-model:value="value[key]" />

            <PayMethodSelect v-else-if="item.type === 'payMethod'" v-model:value="value[key]" />
          </a-form-item>
        </a-col>
      </template>
    </a-row>
    <a-divider v-if="colCount > 0" dashed style="margin-top: 0" />
    <a-flex gap="small" justify="space-between" align="center">
      <a-space>
        <slot name="actions"><div></div></slot>
      </a-space>
      <a-space>
        <a-button type="primary" :loading="searchLoading" @click="onSearch"><SearchOutlined />查询</a-button>
        <a-button :loading="resetLoading" @click="onReset"><RollbackOutlined />重置</a-button>
        <a-button v-if="colCount > 3" type="link" size="small" @click="isExpand = !isExpand">
          <span>{{ isExpand ? '收起' : '展开' }}</span>
          <UpOutlined v-if="isExpand" />
          <DownOutlined v-else />
        </a-button>
      </a-space>
    </a-flex>
  </a-form>
</template>
<script lang="ts" name="DataTableSearchForm" setup>
import { JSX } from 'vue/jsx-runtime'
import { ref, computed } from 'vue'
import { DownOutlined, UpOutlined, SearchOutlined, RollbackOutlined } from '@ant-design/icons-vue'
import { SearchProperty } from './types'
import UserSelect from '../UserSelect.vue'
import TenantSelect from '../TenantSelect.vue'
import ChannelSelect from '../ChannelSelect.vue'
import PayMethodSelect from '../PayMethodSelect.vue'
type DataTableProps = {
  value: Object
  properties: Record<string, SearchProperty>
  searchLoading: boolean
  resetLoading?: boolean
}
const { properties = {}, value = {}, resetLoading = false, searchLoading = false } = defineProps<DataTableProps>()
const slots = defineSlots<{
  actions: () => JSX.Element
}>()
const emits = defineEmits(['search', 'reset'])

const isExpand = ref(false)

const colCount = computed(() => Object.keys(properties).length)

const onTriggerEvent = (value, callback) => {
  if (callback) {
    callback(value)
  }
}
const onReset = () => {
  emits('reset')
}
const onSearch = async () => {
  emits('search', value)
}
</script>
<style lang="less" scoped></style>
