import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult, ListResult } from '@/services/types'
import { PayMethod } from '@/services/admin/payMethod/types'
import { Channel, ChannelCreateInput, ChannelPagedQueryInput, ChannelUpdateInput, ChannelWithPayMethods } from './types'

export const get = async (id: string): Promise<Result<ChannelWithPayMethods>> => {
  const url = baseURL + `/api/admin/channels/${id}`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (query: ChannelPagedQueryInput): Promise<Result<PagedResult<ChannelWithPayMethods>>> => {
  const url = baseURL + '/api/admin/channels'
  var { status, data } = await clinet.get(url, { params: query })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const create = async (input: ChannelCreateInput): Promise<Result<ChannelWithPayMethods>> => {
  const url = baseURL + '/api/admin/channels'
  var { status, data } = await clinet.post(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const update = async (id: string, input: ChannelUpdateInput): Promise<Result<ChannelWithPayMethods>> => {
  const url = baseURL + `/api/admin/channels/${id}`
  var { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const remove = async (id: string): Promise<Result> => {
  const url = baseURL + `/api/admin/channels/${id}`
  var { status } = await clinet.delete(url)
  if (status != 204) return Results.fail()
  return Results.ok()
}

export const getPayMethods = async (id: string): Promise<Result<ListResult<PayMethod>>> => {
  const url = baseURL + `/api/admin/channels/${id}/pay-methods`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
