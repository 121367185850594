<template>
  <a-form :model="formModel">
    <a-form-item label="分组名称" name="name" v-bind="form.validateInfos.name" :rules="formRules.name">
      <a-input v-model:value="formModel.name" placeholder="请输入分组名称" />
    </a-form-item>
  </a-form>
</template>
<script lang="ts" setup>
import { ref, reactive, watch, watchEffect } from 'vue'
import { useForm } from 'ant-design-vue/es/form'
import { VerifierGroup, VerifierGroupCreateInput } from '@/services/tenant/verifierGroup/types'
import * as groupService from '@/services/tenant/verifierGroup'
const props = defineProps<{
  loading: boolean
}>()
const emits = defineEmits(['update:loading'])

const formModel = ref<VerifierGroupCreateInput>({ name: '' })
const formRules = reactive({
  name: [
    { required: true, message: '请输入分组名称', trigger: 'blur' },
    { min: 2, max: 32, message: '名称长度在 2 到 32 个字符之间', trigger: 'blur' }
  ]
})
const isCreating = ref(props.loading)
const form = useForm(formModel, formRules)
const create = async (): Promise<VerifierGroup | undefined> => {
  try {
    await form.validate()
  } catch {
    return
  }

  isCreating.value = true
  const { value } = await groupService.create(formModel.value)
  isCreating.value = false
  form.resetFields()
  return value
}

watch(isCreating, (val) => {
  emits('update:loading', val)
})

defineExpose({ create })
</script>
<style lang="less" scoped></style>
