import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult } from '@/services/types'
import { Order, OrderAbortInput, OrderPagedQueryInput } from './types'

export const Status = {
  Matching: {
    value: 'Matching',
    label: '匹配中'
  },
  Confirming: {
    value: 'Confirming',
    label: '处理中'
  },
  Depositing: {
    value: 'Depositing',
    label: '支付中'
  },
  Completed: {
    value: 'Completed',
    label: '已完成'
  },
  Aborted: {
    value: 'Aborted',
    label: '已终止'
  }
}

export const getStatusTagColor = (status: string) => {
  switch (status) {
    case Status.Matching.value:
      return 'processing'
    case Status.Confirming.value:
      return 'warning'
    case Status.Depositing.value:
      return 'lime'
    case Status.Completed.value:
      return 'success'
    case Status.Aborted.value:
      return 'error'
  }
}

export const get = async (id: string): Promise<Result<Order>> => {
  const url = baseURL + `/api/admin/orders/${id}`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (input: OrderPagedQueryInput): Promise<Result<PagedResult<Order>>> => {
  const url = baseURL + '/api/admin/orders'
  var { status, data } = await clinet.get(url, { params: input })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const remove = async (id: string): Promise<Result> => {
  const url = baseURL + `/api/admin/orders/${id}`
  var { status } = await clinet.delete(url)
  if (status != 204) return Results.fail()
  return Results.ok()
}


export const abort = async (id: string, input: OrderAbortInput): Promise<Result<Order>> => {
  const url = baseURL + `/api/admin/orders/${id}/abort`
  var { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
