<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChannelList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <DataTable ref="dataTable" :columns="columns" :search-properties="searchProperties" :search-params="searchParams" :fetch="fetchData">
      <template #actions>
        <CreateButton @click="onCreate" />
      </template>
      <template #default="{ column, record }">
        <template v-if="column.key === 'icon'">
          <a-avatar shape="square" size="large" :src="record.iconFullUrl">
            <template #icon><AntDesignOutlined /></template>
          </a-avatar>
        </template>
        <template v-else-if="column.key === 'totalDeposit'">
          <span>￥{{ record.totalDeposit }}</span>
        </template>
        <template v-else-if="column.key === 'dailyDeposit'">
          <span>￥{{ record.dailyDeposit }}</span>
        </template>
        <template v-else-if="column.key === 'createdOn'">
          <DateTimeLabel :value="record.createdOn" />
        </template>
        <template v-else-if="column.key === 'modifiedOn'">
          <DateTimeLabel :value="record.modifiedOn" />
        </template>
        <template v-else-if="column.key === 'isEnabled'">
          <BooleanLabel :value="record.isEnabled" />
        </template>
        <template v-else-if="column.key === 'action'">
          <a-space>
            <EditButton type="link" @click="onUpdate(record)" />
            <DeleteButton type="link" :loading="currentRecord?.id == record.id" @delete="onDelete(record)" />
          </a-space>
        </template>
      </template>
    </DataTable>
  </a-flex>
</template>
<script lang="ts" name="ChannelList" setup>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import { AntDesignOutlined } from '@ant-design/icons-vue'
import { Channel, ChannelPagedQueryInput } from '@/services/admin/channel/types'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import CreateButton from '@/components/Standards/CreateButton.vue'
import EditButton from '@/components/Standards/EditButton.vue'
import DeleteButton from '@/components/Standards/DeleteButton.vue'
import columns from './columns.json'
import search from './searchProperties.json'
import * as payMethodService from '@/services/admin/payMethod'
import * as service from '@/services/admin/channel'

const router = useRouter()
const currentRecord = ref<Channel | null>(null)
const dataTable = ref<DataTableInstance | null>(null)
const searchProperties = reactive(search)
const searchParams = reactive<ChannelPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})
const fetchData = (params) => {
  return service.getList(params)
}
const onCreate = () => {
  router.push({ name: 'ChannelCreate' })
}

const onUpdate = async (record: Channel) => {
  router.push({ name: 'ChannelEdit', params: { id: record.id } })
}
const onDelete = async (record: Channel) => {
  currentRecord.value = record
  const { isSuccess } = await service.remove(record.id)
  currentRecord.value = null
  if (isSuccess) {
    notification.success({ message: '删除成功' })
    dataTable.value?.refresh()
  }
}
const loadPayMehtods = async () => {
  const { value } = await payMethodService.getList({ maxResultCount: 100 })
  if (value) {
    searchProperties.payMethodId.options.items = value.items.map((item) => {
      return {
        label: item.name,
        value: item.id
      }
    })
  }
}

onMounted(async () => {
  await loadPayMehtods()
})
</script>
<style lang="less" scoped></style>
