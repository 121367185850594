<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChannelAccountEdit',
})
</script>
<template>
  <a-skeleton v-if="!account" active />
  <a-flex v-else vertical>
    <a-card :tabList="tabList" :active-tab-key="tab" @tabChange="(key) => (tab = key)">
      <ChannelAccountDetails v-if="tab == 'info'" v-model:value="account" />
      <DepositRecords v-else-if="tab == 'items'" v-model:value="account" />
      <a-flex justify="flex-end">
        <a-button @click="router.back">返回</a-button>
      </a-flex>
    </a-card>
  </a-flex>
</template>
<script lang="ts" name="ChannelAccountEdit" setup>
import { ChannelAccountWithRecords } from '@/services/admin/channelAccount/types'
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted, computed } from 'vue'
import * as service from '@/services/admin/channelAccount'
import ChannelAccountDetails from './ChannelAccountDetails.vue'
import DepositRecords from './DepositRecords.vue'
const route = useRoute()
const router = useRouter()
const account = ref<ChannelAccountWithRecords>()
const tabList = [
  {
    key: 'info',
    tab: '账号信息'
  },
  {
    key: 'items',
    tab: '充值记录'
  }
]
const tab = ref(tabList[0].key)

onMounted(async () => {
  const accountId = route.params.id as string
  const { value } = await service.get(accountId)
  if (value) {
    account.value = value
  }
})
</script>
<style lang="less" scoped></style>
