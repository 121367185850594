<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PayMethodUpdateDrawer',
})
</script>
<template>
  <a-drawer v-bind="$attrs" v-model:open="open" :title="`修改 ${value.name}`">
    <a-form :model="formModel" labelAlign="right" :labelCol="{ span: 6 }">
      <a-form-item label="图标">
        <ImageUpload v-model:value="formModel.icon" :url="value.iconFullUrl" folder="payMethod/icon" />
      </a-form-item>
      <a-form-item label="标识">
        <label>{{ value.id }}</label>
      </a-form-item>
      <a-form-item label="名称" name="name" :rules="formRules.name">
        <a-input v-model:value="formModel.name" placeholder="请输入名称" show-count :maxlength="32" />
      </a-form-item>
      <a-form-item label="描述" name="description" :rules="formRules.description">
        <a-textarea v-model:value="formModel.description" placeholder="请输入描述" show-count :maxlength="256" />
      </a-form-item>
      <a-form-item label="启用">
        <a-switch v-model:checked="formModel.isEnabled" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-flex justify="end" gap="small">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :loading="isSubmitting" :disabled="submitDisabled" @click="onSubmit">保存</a-button>
      </a-flex>
    </template>
  </a-drawer>
</template>
<script lang="ts" name="PayMethodUpdateDrawer" setup>
import { ref, reactive, UnwrapRef, watch, computed } from 'vue'
import { PayMethod, PayMethodUpdateInput } from '@/services/admin/payMethod/types'
import { Form, notification } from 'ant-design-vue'
import * as service from '@/services/admin/payMethod'
import { shallowEqual } from '@/utils/util'
import ImageUpload from '@/components/Standards/ImageUpload.vue'
const props = defineProps<{
  visible: boolean
  value: PayMethod
}>()
const emits = defineEmits(['update:visible', 'submitted'])
const useForm = Form.useForm
const open = ref<boolean>(props.visible)
const isSubmitting = ref(false)
const map = (method: PayMethod): PayMethodUpdateInput => {
  return {
    name: method.name,
    description: method.description,
    icon: method.icon,
    isEnabled: method.isEnabled
  }
}
const formModel: UnwrapRef<PayMethodUpdateInput> = reactive(map(props.value))
const formRules = reactive({
  name: [
    { required: true, message: '请输入名称', trigger: 'blur' },
    { max: 32, message: '名称不能超过32个字符', trigger: 'change' }
  ],
  description: [{ max: 256, message: '描述不能超过256个字符', trigger: 'change' }]
})
const submitDisabled = computed(() => shallowEqual(map(props.value), formModel))
const form = useForm(formModel, formRules)
const onSubmit = async () => {
  try {
    await form.validate()
  } catch {
    return
  }

  isSubmitting.value = true
  const { isSuccess } = await service.update(props.value.id, formModel)
  isSubmitting.value = false
  if (isSuccess) {
    notification.success({ message: '修改成功' })
    form.resetFields()
    open.value = false
    emits('submitted')
  }
}
const onCancel = () => {
  open.value = false
}

watch(
  () => props.visible,
  (val) => {
    open.value = val
  }
)
watch(
  () => open.value,
  (val) => {
    emits('update:visible', val)
  }
)
watch(
  () => props.value,
  (val) => {
    if (val) {
      Object.assign(formModel, map(val))
    }
  }
)
</script>
<style lang="less" scoped></style>
