<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'HeaderNotice',
})
</script>
<template>
  <a-popover
    v-model:open="visible"
    trigger="click"
    :placement="isMobile ? 'bottom' : 'bottomRight'"
    overlayClassName="header-notice-wrapper"
    :getPopupContainer="getPopupContainer"
    :overlayStyle="{ width: isMobile ? '250px' : '300px', top: '50px' }"
  >
    <template #content>
      <a-spin :spinning="loading">
        <a-tabs>
          <a-tab-pane tab="通知" key="1">
            <a-empty />
          </a-tab-pane>
          <a-tab-pane tab="消息" key="2">
            <a-empty />
          </a-tab-pane>
          <a-tab-pane tab="待办" key="3">
            <a-empty />
          </a-tab-pane>
        </a-tabs>
      </a-spin>
    </template>
    <span @click="fetchNotice" class="header-notice" ref="noticeRef" style="padding: 0 18px">
      <a-badge :count="noticeCount">
        <BellOutlined style="font-size: 16px; padding: 4px" />
      </a-badge>
    </span>
  </a-popover>
</template>

<script lang="ts" setup name="HeaderNotice">
import { ref } from 'vue'
import { BellOutlined } from '@ant-design/icons-vue'
import { isMobile } from '@/utils/device'

const loading = ref<boolean>(false)
const visible = ref<boolean>(false)
const noticeRef = ref(null)
const noticeCount = ref(0)

const fetchNotice = () => {
  if (!visible.value) {
    loading.value = true
    setTimeout(() => {
      loading.value = false
    }, 300)
  } else {
    loading.value = false
  }
}

const getPopupContainer = () => {
  return noticeRef.value!.parentElement
}
</script>

<style lang="css">
.header-notice-wrapper {
  top: 50px !important;
}
</style>
<style lang="less" scoped>
.header-notice {
  display: inline-block;
  transition: all 0.3s;
}
</style>
