<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Exception404',
})
</script>
<template>
  <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
    <template #extra>
      <a-button type="primary" @click="toHome">
        Back Home
      </a-button>
    </template>
  </a-result>
</template>

<script lang="ts" name="Exception404" setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const toHome = () => {
  router.push({ path: '/' })
}
console.debug(router.getRoutes())
</script>
