import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { ListResult } from '@/services/types'
import { PermissionDefinition, RolePermissionSetInput } from './types'

export const getDefinition = async (): Promise<Result<ListResult<PermissionDefinition>>> => {
  const url = baseURL + `/api/admin/permissions/definitions`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (): Promise<Result<ListResult<string>>> => {
  const url = baseURL + `/api/admin/permissions`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getListByRole = async (role: string): Promise<Result<ListResult<string>>> => {
  const url = baseURL + `/api/admin/permissions/${role}`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const setForRole = async (input: RolePermissionSetInput): Promise<Result<ListResult<string>>> => {
  const url = baseURL + `/api/admin/permissions`
  var { status } = await clinet.post(url, input)
  if (status != 204) return Results.fail()
  return Results.ok()
}
