import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { ListResult } from '@/services/types'
import { PayMethod } from './types'
export const getList = async (): Promise<Result<ListResult<PayMethod>>> => {
  const url = baseURL + '/api/pay-methods'
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
