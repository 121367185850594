<template>
  <a-card v-bind="$attrs" :bordered="false" hoverable>
    <a-flex vertical>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-avatar shape="square" size="large" :src="value.icon">
            <template #icon>
              <AntDesignOutlined />
            </template>
          </a-avatar>
        </a-col>
        <a-col :span="18">
          <div>
            <a-space>
              <span style="font-size: 1rem">{{ value.name }}</span>
              <BooleanLabel :value="value.isEnabled" />
            </a-space>
          </div>
          <div class="description">
            <span style="font-size: 0.7rem; color: rgba(0, 0, 0, 0.5)">{{ value.description }}</span>
          </div>
        </a-col>
      </a-row>
      <a-flex style="margin-top: 1rem">
        <a-tag :color="item.isEnabled ? 'success' : 'error'" v-for="item in value.payMethods">{{ item.name }}</a-tag>
      </a-flex>
    </a-flex>

    <template #actions>
      <QrcodeOutlined v-if="!qrCodeLoading" @click="onAction('qrcode')" />
      <SyncOutlined v-else :spin="true"/>
      <QuestionCircleOutlined @click="onAction('document')" />
      <DownloadOutlined @click="onAction('download')" />
    </template>
  </a-card>
</template>
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { Channel as TenantChannel } from '@/services/tenant/channel/types'
import { ChannelWithPayMethods } from '@/services/admin/channel/types'
import { AntDesignOutlined, DownloadOutlined, QrcodeOutlined, QuestionCircleOutlined, SyncOutlined } from '@ant-design/icons-vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
const props = defineProps<{
  value: TenantChannel | ChannelWithPayMethods
  qrCodeLoading?: boolean
}>()
const emits = defineEmits(['action'])
const onAction = (key: string) => {
  emits('action', key, props.value)
}
</script>
<style lang="less" scoped>
.description {
  height: 2.2rem;
  width: 100%;
  line-height: 1.1rem;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
