<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserSelect',
})
</script>
<template>
  <a-select
    v-bind="$attrs"
    v-model:value.trim="search"
    placeholder="请输入用户名..."
    style="width: 100%"
    :options="options"
    :not-found-content="loading ? undefined : null"
    :filter-option="false"
    showSearch
    allowClear
    @search="onSearch"
    @select="onSelected"
    @blur="onBlur"
  >
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>
<script lang="ts" name="UserSelect" setup>
import { ref, reactive, watchEffect, PropType, computed, onMounted } from 'vue'
import { User, UserPagedQueryInput } from '@/services/admin/user/types'
import { User as TenantUser } from '@/services/tenant/user/types'
import { debounce } from '@/utils/util'
import storage from '@/utils/Storage'
import { USER_INFO } from '@/store/mutation-types'
import { LoginUser } from '@/services/auth/types'
import * as adminUserService from '@/services/admin/user'
import * as tenantUserService from '@/services/tenant/user'

const props = defineProps({
  value: {
    type: String,
    required: true
  },
  searchParams: {
    type: Object as PropType<UserPagedQueryInput>,
    required: false,
    default: () => ({ userName: '' })
  }
})
const emits = defineEmits(['update:value'])

const loginUser = ref<LoginUser>(storage.get(USER_INFO))
const search = ref('')
const loading = ref(false)
const options = reactive([])
const selected = ref<User | TenantUser | undefined>(undefined)
const searchParams = reactive<UserPagedQueryInput>({ ...props.searchParams })
const fetchData = async (input: string) => {
  searchParams.userName = input
  loading.value = true
  const users = await getUserList()
  loading.value = false
  if (users) {
    options.splice(0, options.length)
    users.forEach((item) => {
      options.push({
        label: item.userName,
        value: item
      })
    })
  }
}
const onSecarchChanged = async (value: string) => {
  console.debug('onSecarchChanged', value)
  if (value === '') {
    options.splice(0, options.length)
    selected.value = null
    emits('update:value', null)
    return
  }
  await fetchData(value)
}

const onSearch = debounce(onSecarchChanged, 300)

const onSelected = (value: User) => {
  console.debug('onSelected', value)
  selected.value = value
  search.value = selected.value.userName
  emits('update:value', value.id)
}
const onBlur = () => {
  console.debug('onBlur', search.value, selected.value, options)
  if (!search.value) {
    selected.value = null
    emits('update:value', null)
    return
  }

  if (selected.value !== null) {
    const filter = options.filter((item) => item.value.id === selected.value.id)
    if (options.length > 0 && filter.length === 0) {
      selected.value = options[0].value
    }
    search.value = selected.value.userName
    emits('update:value', selected.value.id)
    return
  }

  if (!search.value && options.length === 0) {
    options.splice(0, options.length)
    emits('update:value', null)
    return
  }

  selected.value = options[0].value
  search.value = selected.value.userName
  emits('update:value', selected.value.id)
}

const isTenant = computed(() => {
  const tenantRole = loginUser.value.roles.find((x) => x.id === 'tenant')
  console.debug('isTenant', tenantRole)
  return tenantRole
})
const getUserList = async () => {
  if (isTenant.value) {
    const { value } = await tenantUserService.getList(searchParams)
    return value?.items
  }
  const { value } = await adminUserService.getList(searchParams)
  return value?.items
}

const getUser = async (id: string) => {
  if (isTenant.value) {
    const { value } = await tenantUserService.get(id)
    return value
  }
  const { value } = await adminUserService.get(id)
  return value
}

watchEffect(async () => {
  if (props.value) {
    const user = await getUser(props.value)
    if (user) {
      selected.value = user
      search.value = selected.value.userName
    }
  }
  if (props.searchParams) {
    Object.assign(searchParams, props.searchParams)
  }
})
onMounted(() => {
  loginUser.value = storage.get(USER_INFO) as LoginUser
})
</script>
<style lang="less" scoped></style>
