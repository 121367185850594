<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PayMethodSelect',
})
</script>
<template>
  <a-select v-model:value="methodId" placeholder="请选择支付方式" allowClear showSearch>
    <a-select-option v-for="item in methodList" :key="item.id" :value="item.id">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>
<script lang="ts" name="PayMethodSelect" setup>
import { ref, onMounted, watch, watchEffect } from 'vue'
import * as service from '@/services/verifier/method'
import { PayMethod } from '@/services/verifier/method/types'
const props = withDefaults(
  defineProps<{
    value: string
  }>(),
  { value: '' }
)
const emits = defineEmits(['update:value'])
const methodId = ref(props.value)
const methodList = ref<PayMethod[]>([])
onMounted(async () => {
  const { value } = await service.getList()
  methodList.value = value.items
})

watch(methodId, () => {
  emits('update:value', methodId.value)
})
watchEffect(() => {
  methodId.value = props.value
})
</script>
<style lang="less" scoped></style>
