<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantVerifierList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <DataTable ref="dataTable" :columns="columns" :search-properties="searchProperties" :search-params="searchParams" :fetch="fetchData">
      <template #actions>
        <CreateButton @click="onCreate" />
      </template>
      <template #default="{ column, record }">
        <template v-if="column.key === 'id'">
          <a-popover v-if="record.remark" title="备注">
            <template #content>
              <p>{{ record.remark }}</p>
            </template>
            <span>{{ record.user.userName }}</span>
          </a-popover>
          <span v-else>{{ record.user.userName }}</span>
        </template>
        <template v-else-if="column.key === 'groupId'">
          <span>{{ record.group.name }}</span>
        </template>
        <template v-else-if="column.key === 'createdOn'">
          <DateTimeLabel :value="record.createdOn" />
        </template>
        <template v-else-if="column.key === 'modifiedOn'">
          <DateTimeLabel :value="record.modifiedOn" />
        </template>
        <template v-else-if="column.key === 'isEnabled'">
          <div v-if="isCellEdit(record, column.key)">
            <a-switch v-model:checked="editItem.isEnabled" :loading="isCellEditing" @change="onCellEditSave" />
          </div>
          <div v-else>
            <BooleanLabel :value="record.isEnabled" />
            <EditOutlined class="editable-cell-icon" @click="onCellEdit(record, column.key)" />
          </div>
        </template>
        <template v-else-if="column.key === 'action'">
          <a-space>
            <DetailButton @click="onEdit(record)" />
            <DeleteButton title="删除核销员将会删除该核销员的所有通道账号，确定要删除吗？" @delete="onDelete(record)" />
          </a-space>
        </template>
      </template>
    </DataTable>
  </a-flex>
  <VerifierCreateDrawer v-model:visible="isVisibleCreate" @created="onSaveSucceed" />
  <VerifierUpdateDrawer v-if="currentRecord" v-model:visible="isVisibleEdit" :value="currentRecord" @updated="onSaveSucceed" />
</template>
<script lang="ts" name="TenantVerifierList" setup>
import { ref, reactive } from 'vue'
import { notification } from 'ant-design-vue'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import CreateButton from '@/components/Standards/CreateButton.vue'
import DetailButton from '@/components/Standards/DetailButton.vue'
import DeleteButton from '@/components/Standards/DeleteButton.vue'
import columns from './columns.json'
import searchProperties from './searchProperties.json'
import { Verifier, VerifierPagedQueryInput } from '@/services/tenant/verifier/types'
import * as service from '@/services/tenant/verifier/index'
import VerifierCreateDrawer from './VerifierCreateDrawer.vue'
import VerifierUpdateDrawer from './VerifierUpdateDrawer.vue'
import { EditOutlined } from '@ant-design/icons-vue'

const searchParams = reactive<VerifierPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})
const dataTable = ref<DataTableInstance>()
const fetchData = (params) => {
  return service.getList(params)
}

const editItem = ref<Verifier>()
const editKey = ref('')
const isCellEdit = (item: Verifier, key: string) => {
  return item.id === editItem.value?.id && key === editKey.value
}
const onCellEdit = (item: Verifier, key: string) => {
  editItem.value = { ...item }
  editKey.value = key
}

const onCellEditCancel = () => {
  editItem.value = null
  editKey.value = ''
}

const isCellEditing = ref(false)
const onCellEditSave = async () => {
  isCellEditing.value = true
  const { value } = await service.update(editItem.value.id, service.map(editItem.value))
  isCellEditing.value = false
  if (value) {
    onCellEditCancel()
    dataTable.value?.refresh()
  }
}

const isVisibleCreate = ref(false)
const onCreate = () => {
  isVisibleCreate.value = true
}
const onSaveSucceed = () => {
  console.debug('onCreateSucceed')
  dataTable.value?.refresh()
}
const currentRecord = ref<Verifier | null>(null)
const isVisibleEdit = ref(false)
const onEdit = (item: Verifier) => {
  currentRecord.value = item
  isVisibleEdit.value = true
}
const onDelete = async (item: Verifier) => {
  currentRecord.value = item
  const { isSuccess } = await service.remove(item.id)
  if (isSuccess) {
    notification.success({ message: '删除成功' })
    dataTable.value?.refresh()
  }
}
</script>
<style lang="less" scoped>
.editable-cell-icon {
  position: absolute;
  right: 0;
  top: 40%;
  cursor: pointer;
}
.editable-cell-icon:hover {
  color: #108ee9;
}
</style>
