import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult } from '@/services/types'
import { User, UserCreateInput, UserPagedQueryInput, UserResetPasswordOutput, UserUpdateInput, UserWithRoles } from './types'

export const map = (user: User): UserUpdateInput => {
  return {
    isActive: user.isActive,
    lockoutEnd: user.lockoutEnd
  }
}
export const get = async (id: string): Promise<Result<UserWithRoles>> => {
  const url = baseURL + `/api/admin/users/${id}`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (query: UserPagedQueryInput): Promise<Result<PagedResult<User>>> => {
  const url = baseURL + '/api/admin/users'
  var { status, data } = await clinet.get(url, { params: query })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const create = async (input: UserCreateInput): Promise<Result<User>> => {
  const url = baseURL + '/api/admin/users'
  var { status, data } = await clinet.post(url, input)
  if (status != 200) return Results.fail(data.error.message)
  return Results.ok(data)
}

export const update = async (id: string, input: UserUpdateInput): Promise<Result<UserWithRoles>> => {
  const url = baseURL + `/api/admin/users/${id}`
  var { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail(data.error.message)
  return Results.ok(data)
}

export const remove = async (id: string): Promise<Result> => {
  const url = baseURL + `/api/admin/users/${id}`
  var { status, data } = await clinet.delete(url)
  if (status != 204) return Results.fail(data.error.message)
  return Results.ok()
}

export const resetPassword = async (id: string): Promise<Result<UserResetPasswordOutput>> => {
  const url = baseURL + `/api/admin/users/${id}/password`
  var { status, data } = await clinet.put(url)
  if (status != 200) return Results.fail(data.error.message)
  return Results.ok(data)
}

export const addRole = async (id: string, role: string): Promise<Result<UserWithRoles>> => {
  const url = baseURL + `/api/admin/users/${id}/roles/${role}`
  var { status, data } = await clinet.post(url)
  if (status != 200) return Results.fail(data.error.message)
  return Results.ok(data)
}

export const removeRole = async (id: string, role: string): Promise<Result<UserWithRoles>> => {
  const url = baseURL + `/api/admin/users/${id}/roles/${role}`
  var { status, data } = await clinet.delete(url)
  if (status != 200) return Results.fail(data.error.message)
  return Results.ok(data)
}
