<template>
  <a-popconfirm :title="title" :disabled="disabled" ok-text="删除" cancel-text="取消" @confirm="onDelete" @cancel="onCancel">
    <a-button v-bind="$attrs" :type="type" :danger="danger" :disabled="disabled">
      <DeleteOutlined v-if="showIcon" />
      <span>删除</span>
    </a-button>
  </a-popconfirm>
</template>
<script lang="ts" setup>
import { DeleteOutlined } from '@ant-design/icons-vue'
defineProps({
  title: {
    type: String,
    required: false,
    default: '确定要删除此项吗？'
  },
  type: {
    type: String,
    required: false,
    default: 'link'
  },
  danger: {
    type: Boolean,
    required: false,
    default: true
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  showIcon: {
    type: Boolean,
    required: false,
    default: true
  }
})
const emit = defineEmits(['delete', 'cancel'])
const onDelete = () => {
  emit('delete')
}
const onCancel = () => {
  emit('cancel')
}
</script>
<style lang="less" scoped></style>
