<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <DataTable ref="dataTable" :columns="columns" :search-properties="searchProperties" :search-params="searchParams" :fetch="fetchData">
      <template #actions>
        <CreateButton @click="() => (isCreateVisible = true)" />
      </template>
      <template #default="{ column, record }">
        <template v-if="column.key === 'tenantId'">
          {{ record.tenant?.name ?? '-' }}
        </template>
        <template v-if="column.key === 'createdOn'">
          <DateTimeLabel :value="record.createdOn" />
        </template>
        <template v-if="column.key === 'modifiedOn'">
          <DateTimeLabel :value="record.modifiedOn" />
        </template>
        <template v-else-if="column.key === 'isActive'">
          <BooleanLabel :value="record.isActive" />
        </template>
        <template v-else-if="column.key === 'action'">
          <a-space>
            <DetailButton @click="onEdit(record)" />
            <DeleteButton :loading="currentRecord?.id == record.id && isDeleting" @delete="onDelete(record)" />
          </a-space>
        </template>
      </template>
    </DataTable>
  </a-flex>

  <UserCreateDrawer v-model:visible="isCreateVisible" @submitted="dataTable?.refresh()" />
  <UserEditDrawer v-if="currentRecord" v-model:visible="isEditVisible" v-model:value="currentRecord" @submitted="dataTable?.refresh()" />
</template>
<script lang="ts" name="UserList" setup>
import { ref, reactive } from 'vue'
import { User, UserPagedQueryInput } from '@/services/admin/user/types'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
import DeleteButton from '@/components/Standards/DeleteButton.vue'
import DetailButton from '@/components/Standards/DetailButton.vue'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import CreateButton from '@/components/Standards/CreateButton.vue'
import UserCreateDrawer from './UserCreateDrawer.vue'
import columns from './columns.json'
import searchProperties from './searchProperties.json'
import * as service from '@/services/admin/user'
import { notification } from 'ant-design-vue'
import UserEditDrawer from './UserEditDrawer.vue'

const isCreateVisible = ref(false)
const isEditVisible = ref(false)
const dataTable = ref<DataTableInstance | null>(null)
const searchParams = reactive<UserPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})

const fetchData = (params) => {
  return service.getList(params)
}
const currentRecord = ref<User>()
const onEdit = (item: User) => {
  currentRecord.value = item
  isEditVisible.value = true
}
const isDeleting = ref(false)
const onDelete = async (item: User) => {
  currentRecord.value = item
  isDeleting.value = true
  const { isSuccess } = await service.remove(item.id)
  isDeleting.value = false
  if (isSuccess) {
    notification.success({ message: '删除成功' })
    dataTable.value?.refresh()
  }
}
</script>
<style lang="less" scoped></style>
