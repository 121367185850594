<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'VerifierDashhoard',
})
</script>
<template>
  <a-skeleton v-if="!verifier" active :loading="loading" />
  <a-flex v-else vertical gap="middle">
    <Profile :value="verifier" />
  </a-flex>
</template>
<script lang="ts" name="VerifierDashhoard" setup>
import { ref, onMounted } from 'vue'
import Profile from './Profile.vue'
import { Verifier } from '@/services/verifier/types'
import * as service from '@/services/verifier'

const verifier = ref<Verifier>()
const loading = ref(false)

const fetchData = async () => {
  loading.value = true
  const { value } = await service.get()
  loading.value = false
  if (value) {
    verifier.value = value
  }
}
onMounted(() => {
  fetchData()
})
</script>
<style lang="less" scoped></style>
