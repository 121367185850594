<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DataTable',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <a-card v-if="searchProperties && Object.keys(searchProperties).length > 0">
      <DataTableSearchForm :value="props.searchParams" :properties="props.searchProperties" :search-loading="loading" @search="onSearch" @reset="onReset">
        <template #actions>
          <slot name="actions"></slot>
        </template>
      </DataTableSearchForm>
    </a-card>
    <a-table :data-source="dataSource" :columns="props.columns" :pagination="pagination" :loading="loading" @change="onTableChange">
      <template #bodyCell="{ column, record }">
        <slot name="default" :column="column" :record="record"></slot>
      </template>
    </a-table>
  </a-flex>
</template>
<script lang="ts" name="DataTable" setup>
import { JSX } from 'vue/jsx-runtime'
import { ref, computed, onMounted } from 'vue'
import { toUpperCamelCase } from '@/utils/util'
import { BasicPagedQueryInput, PagedResult, ListResult } from '@/services/types'
import { SearchProperty } from './types'
import { Result } from '@/utils/results'
import DataTableSearchForm from './DataTableSearchForm.vue'

interface DataTableProps {
  columns: Array<Object>
  searchProperties?: Record<string, SearchProperty>
  searchParams?: BasicPagedQueryInput
  fetch: (input: BasicPagedQueryInput | undefined) => Promise<Result<PagedResult<Object> | ListResult<Object>>>
}
const props = withDefaults(defineProps<DataTableProps>(), {
  searchParams: () => ({
    skipCount: 0,
    maxResultCount: 10
  })
})
const solts = defineSlots<{
  default: (props: { column: any; record: any }) => JSX.Element
  actions: () => JSX.Element
}>()

const originalSearchParams = { ...props.searchParams }
const loading = ref(false)
const dataList = ref<PagedResult<Object> | ListResult<Object>>({ items: [], totalCount: 0 })

const dataSource = computed(() => dataList.value.items)
const pagination = computed(() => {
  if ('totalCount' in dataList.value) {
    return {
      total: dataList.value.totalCount,
      current: props.searchParams.skipCount / props.searchParams.maxResultCount + 1,
      pageSize: props.searchParams.maxResultCount,
      showTotal: (total) => `共 ${total} 条`
    }
  }
  return false
})

const onTableChange = async (pag, _filters, sorter) => {
  if (pag) {
    props.searchParams.maxResultCount = pag.pageSize
    props.searchParams.skipCount = (pag.current - 1) * pag.pageSize
  }
  if (sorter?.field) {
    const field = toUpperCamelCase(sorter.field)
    const order = sorter.order === 'ascend' ? 'ASC' : 'DESC'
    props.searchParams.sorting = `${field} ${order}`
  }
  await fetchData()
}
const onReset = () => {
  Object.assign(props.searchParams, originalSearchParams)
}
const onSearch = async () => {
  props.searchParams.skipCount = 0
  await fetchData()
}
const fetchData = async () => {
  loading.value = true
  const { isSuccess, value } = await props.fetch(props?.searchParams)
  loading.value = false
  if (isSuccess) {
    dataList.value = value
  }
}
const refresh = async () => {
  await fetchData()
}
onMounted(() => fetchData())
defineExpose({ refresh })
</script>
