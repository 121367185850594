import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult } from '@/services/types'
import { PayMethod, PayMethodCreateInput, PayMethodPagedQueryInput, PayMethodUpdateInput } from './types'

export const get = async (id: string): Promise<Result<PayMethod>> => {
  const url = baseURL + `/api/admin/pay-methods/${id}`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (query: PayMethodPagedQueryInput): Promise<Result<PagedResult<PayMethod>>> => {
  const url = baseURL + '/api/admin/pay-methods'
  var { status, data } = await clinet.get(url, { params: query })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const create = async (input: PayMethodCreateInput): Promise<Result<PayMethod>> => {
  const url = baseURL + '/api/admin/pay-methods'
  var { status, data } = await clinet.post(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const update = async (id: string, input: PayMethodUpdateInput): Promise<Result<PayMethod>> => {
  const url = baseURL + `/api/admin/pay-methods/${id}`
  var { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const remove = async (id: string): Promise<Result> => {
  const url = baseURL + `/api/admin/pay-methods/${id}`
  var { status } = await clinet.delete(url)
  if (status != 204) return Results.fail()
  return Results.ok()
}
