<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantVerifierCreateDrawer',
})
</script>
<template>
  <a-drawer v-bind="$attrs" v-model:open="open" title="新建核销员">
    <a-form :model="formModel" labelAlign="right" :labelCol="{ span: 6 }">
      <a-form-item label="用户名称" name="userId" :rules="formRules.userId" v-bind="form.validateInfos.userId">
        <UserSelectOrCreate v-model:value="formModel.userId" />
      </a-form-item>
      <a-form-item label="核销分组" name="groupId" :rules="formRules.groupId" v-bind="form.validateInfos.groupId">
        <GroupSelectOrCreate v-model:value="formModel.groupId" />
      </a-form-item>
      <a-form-item label="备注信息" name="remark" :rules="formRules.remark" v-bind="form.validateInfos.description">
        <a-textarea v-model:value="formModel.remark" placeholder="请输入备注信息" show-count :maxlength="256" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-flex justify="end" gap="small">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :loading="isCreating" @click="onSubmit">创建</a-button>
      </a-flex>
    </template>
  </a-drawer>
</template>
<script lang="ts" name="TenantVerifierCreateDrawer" setup>
import { ref, reactive, UnwrapRef, watch, onMounted } from 'vue'
import { Form, notification } from 'ant-design-vue'
import { VerifierCreateInput } from '@/services/tenant/verifier/types'
import GroupSelectOrCreate from './GroupSelectOrCreate.vue'
import UserSelectOrCreate from './UserSelectOrCreate.vue'
import * as service from '@/services/tenant/verifier'

const props = defineProps<{
  visible: boolean
}>()
const emits = defineEmits(['update:visible', 'created'])
const useForm = Form.useForm

const open = ref<boolean>(props.visible)
const isCreating = ref(false)
const formModel: UnwrapRef<VerifierCreateInput> = reactive({
  userId: '',
  groupId: null,
  remark: ''
})
const formRules = reactive({
  userId: [{ required: true, message: '请选择一个用户', trigger: 'blur' }],
  groupId: [{ required: true, message: '请选择一个分组', trigger: 'blur' }],
  remark: [{ max: 256, message: '备注信息不能超过256个字符', trigger: 'change' }]
})
const form = useForm(formModel, formRules)
const onSubmit = async () => {
  try {
    await form.validate()
  } catch {
    return
  }

  isCreating.value = true
  const { isSuccess, value } = await service.create(formModel)
  isCreating.value = false
  if (isSuccess) {
    notification.success({ message: '创建成功' })
    form.resetFields()
    open.value = false
    emits('created', value)
  }
}
const onCancel = () => {
  open.value = false
}

watch(
  () => props.visible,
  (val) => {
    open.value = val
  }
)
watch(
  () => open.value,
  (val) => {
    emits('update:visible', val)
  }
)
</script>
<style lang="less" scoped></style>
