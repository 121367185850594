<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantCreate',
})
</script>
<template>
  <a-skeleton v-if="tenant == null" active />
  <a-flex v-else>
    <a-card style="width: 100%" :tabList="tabList" :active-tab-key="tabKey" @tabChange="(key) => (tabKey = key)">
      <TenantDetail v-if="tabKey === 'tenant'" :value="tenant" />
      <TenantChannels v-else-if="tabKey === 'channels'" :value="tenant" />
    </a-card>
  </a-flex>
</template>
<script lang="ts" name="TenantCreate" setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { TenantWithChannels } from '@/services/admin/tenant/types'
import * as service from '@/services/admin/tenant'
import TenantDetail from './TenantDetail.vue'
import TenantChannels from './TenantChannels.vue'

const { params } = useRoute()
const tenant = ref<TenantWithChannels | null>(null)
const tabList = [
  {
    key: 'tenant',
    tab: '商家详情'
  },
  {
    key: 'channels',
    tab: '支付通道'
  }
]
const tabKey = ref('tenant')

onMounted(async () => {
  const { value } = await service.get(params.id as string)
  if (value) {
    tenant.value = value
  }
})
</script>
<style lang="less" scoped></style>
