<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CreateButton',
})
</script>
<template>
  <a-button v-bind="$attrs" :type="type">
    <PlusOutlined v-if="showIcon" />
    <span>新建</span>
  </a-button>
</template>
<script lang="ts" name="CreateButton" setup>
import { PlusOutlined } from '@ant-design/icons-vue'
defineProps({
  type: {
    type: String,
    default: 'primary'
  },
  showIcon: {
    type: Boolean,
    default: true
  }
})
</script>
<style lang="less" scoped></style>
