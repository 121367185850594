<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Login',
})
</script>
<template>
  <div class="main">
    <a-form id="formLogin" class="user-layout-login" @submit="onSubmit" :model="formRef">
      <a-alert v-if="state.isLoginFailed" type="error" showIcon style="margin-bottom: 24px" :message="state.loginError" />

      <a-form-item v-bind="validateInfos.userName">
        <a-input size="large" type="text" placeholder="登录用户" v-model:value="formRef.userName">
          <template #prefix>
            <UserOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item v-bind="validateInfos.password">
        <a-input-password size="large" placeholder="登录密码" v-model:value="formRef.password">
          <template #prefix>
            <LockOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />
          </template>
        </a-input-password>
      </a-form-item>

      <a-form-item style="margin-top: 24px">
        <a-button size="large" type="primary" htmlType="submit" class="login-button" :loading="state.loginBtn" :disabled="state.loginBtn">登录</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script lang="ts" setup name="Login">
import { reactive, UnwrapRef } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Form, notification } from 'ant-design-vue'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { timeFix } from '@/utils/util'
import { SignInInput } from '@/services/auth/types'
import * as authService from '@/services/auth'

const useForm = Form.useForm
const router = useRouter()
const route = useRoute()

const state = reactive({
  time: 60,
  loginBtn: false,
  isLoginFailed: false,
  loginError: '登录失败'
})
const formRef: UnwrapRef<SignInInput> = reactive({
  userName: '',
  password: ''
})
const userNameValidator = (rule, value: string) => {
  const regex = /^[a-zA-Z0-9]{4,32}$/
  if (regex.test(value)) {
    return Promise.resolve()
  } else {
    return Promise.reject('用户名只能由 4-32 位的字母、数字组成')
  }
}
const rulesRef = reactive({
  userName: [
    {
      required: true,
      message: '请输入用户名'
    },
    {
      validator: userNameValidator,
      trigger: 'blur'
    }
  ],
  password: [
  { required: true, message: '请输入登录密码' },
  { min: 6, message: '密码长度不能小于6位' }]
})
const { validate, validateInfos } = useForm(formRef, rulesRef)
const onSubmit = async (e: Event) => {
  e.preventDefault()
  state.loginBtn = true

  try {
    await validate()
  } catch {
    state.loginBtn = false
    return
  }

  const { isSuccess, error, value } = await authService.signIn(formRef)
  state.loginBtn = false
  if (isSuccess) {
    state.isLoginFailed = false
    setTimeout(() => {
      notification.success({
        message: '欢迎',
        description: `${value.userName} ${timeFix()}，欢迎回来~`
      })
    }, 1000)
    const redirect = (route.query?.redirect as string) ?? '/'
    location.href = redirect
    //router.push(redirect)
    return
  }

  state.isLoginFailed = true
  state.loginError = error ?? '登录失败'
  state.loginBtn = false
}
</script>

<style lang="less" scoped>
@import '../../style/index.less';

.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .anticon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
