import clinet from '@/utils/http/axios'
import storage from '@/utils/Storage'
import router from '@/router'
import { Result, Results } from '@/utils/results'
import { baseURL } from '@/utils/util'
import { SignInInput, AuthToken, LoginUser, UserPasswordModifyInput } from './types'
import { ACCESS_TOKEN, PERMISSION, USER_INFO } from '@/store/mutation-types'
import { initRoute, resetRoute } from '@/router/routerHelper'

const getToken = async (input: SignInInput): Promise<Result<AuthToken>> => {
  const url = baseURL + '/api/auth/sign-in'
  var { status, data } = await clinet.post(url, input)
  if (status === 200) {
    return Results.ok(data)
  }
  const error = data?.error
  let errorMessage = error?.message || '登录失败'
  if (error?.validationErrors) {
    errorMessage = error?.validationErrors[0].message
  }
  return Results.fail(errorMessage)
}
export const getUserInfo = async (): Promise<Result<LoginUser>> => {
  const url = baseURL + '/api/auth/me'
  var { status, data } = await clinet.get(url)
  if (status === 200) {
    storage.set(USER_INFO, data)
    return Results.ok(data)
  }
  return Results.fail()
}

export const signIn = async (input: SignInInput): Promise<Result<LoginUser>> => {
  const res = await getToken(input)
  if (!res.isSuccess) return Results.fail(res.error)

  storage.set(ACCESS_TOKEN, res.value.accessToken, res.value.expires - new Date().getTime() / 1000)
  const { isSuccess, value } = await getUserInfo()
  if (!isSuccess) return Results.fail('获取用户信息失败')

  if (!value.roles || value.roles.length <= 0) {
    router.push('/exception/403')
    return Results.fail('此用户无权限访问')
  }

  initRoute(router)
  return Results.ok(value)
}
export const signOut = () => {
  resetRoute(router)
  storage.remove(ACCESS_TOKEN)
  storage.remove(USER_INFO)
  router.push({ name: 'login' })
}

export const moidfyPassword = async (input: UserPasswordModifyInput): Promise<Result> => {
  const url = baseURL + '/api/auth/password'
  var { status } = await clinet.put(url, input)
  if (status === 204) {
    return Results.ok()
  }
  return Results.fail()
}
