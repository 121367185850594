<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserCreateDrawer',
})
</script>
<template>
  <a-drawer v-bind="$attrs" v-model:open="open" title="新建用户">
    <UserCreateForm ref="form" v-model:value="formModel" />
    <template #footer>
      <a-flex justify="end" gap="small">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :loading="isCreating" @click="onSubmit">创建</a-button>
      </a-flex>
    </template>
  </a-drawer>
</template>
<script lang="ts" name="UserCreateDrawer" setup>
import { ref, reactive, watch } from 'vue'
import { notification } from 'ant-design-vue'
import * as service from '@/services/admin/user'
import UserCreateForm from './UserCreateForm.vue'
import { UserCreateFormInstance } from './types'
const props = defineProps<{
  visible: boolean
}>()
const emits = defineEmits(['update:visible', 'submitted'])
const open = ref<boolean>(props.visible)
const form = ref<UserCreateFormInstance | null>(null)
const isCreating = ref(false)
const formModel = ref({
  userName: '',
  password: ''
})

const onSubmit = async () => {
  if (!(await form.value.validate())) return

  isCreating.value = true
  const { isSuccess } = await service.create(formModel.value)
  isCreating.value = false
  if (isSuccess) {
    notification.success({ message: '创建成功' })
    form.value.resetFields()
    open.value = false
    emits('submitted')
  }
}
const onCancel = () => {
  open.value = false
}

watch(
  () => props.visible,
  (val) => {
    open.value = val
  }
)
watch(
  () => open.value,
  (val) => {
    emits('update:visible', val)
  }
)
</script>
<style lang="less" scoped></style>
