import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult, ListResult } from '@/services/types'
import { Channel, ChannelWithNonce, ChannelPagedQueryInput, PayMethod, ChannelUpdateInput } from './types'

export const get = async (id: string): Promise<Result<ChannelWithNonce>> => {
  const url = baseURL + `/api/tenant/channels/${id}`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (query: ChannelPagedQueryInput): Promise<Result<PagedResult<Channel>>> => {
  const url = baseURL + '/api/tenant/channels'
  var { status, data } = await clinet.get(url, { params: query })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getPayMethods = async (): Promise<Result<ListResult<PayMethod>>> => {
  const url = baseURL + '/api/tenant/pay-methods'
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const update = async (id: string, input: ChannelUpdateInput): Promise<Result<ChannelWithNonce>> => {
  const url = `${baseURL}/api/tenant/channels/${id}`
  var { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
