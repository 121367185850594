<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserMenu',
})
</script>
<template>
  <div class="user-wrapper">
    <div class="content-box">
      <notice-icon class="action" />
      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <a-avatar class="avatar" size="small" src="/avatar.png" />
          <span class="nickname">{{ user.userName }}</span>
        </span>
        <template #overlay>
          <a-menu class="user-dropdown-menu-wrapper">
            <a-menu-item key="0">
              <router-link to="/user/modify-password">
                <EditOutlined />
                <span>修改密码</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="4" @click="showSystemSetting">
              <a>
                <SettingOutlined />
                <span>系统设置</span>
              </a>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="3">
              <a href="javascript:;" @click="handleLogout">
                <LogoutOutlined />
                <span>退出登录</span>
              </a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup name="UserMenu">
import NoticeIcon from '@/components/NoticeIcon/index.vue'
import { USER_INFO } from '@/store/mutation-types'
import { Modal } from 'ant-design-vue'
import { SettingOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons-vue'
import { systemConfig } from '@/store/reactiveState'
import store from '@/utils/Storage'
import { useRouter } from 'vue-router'
import { LoginUser } from '@/services/auth/types'
import * as authService from '@/services/auth'

const props = defineProps(['theme'])
const router = useRouter()
const user = store.get(USER_INFO) as LoginUser
const handleLogout = () => {
  Modal.confirm({
    title: '操作确认',
    content: '您确定退出登录吗？',
    okText: '退出',
    okType: 'danger',
    onOk: () => authService.signOut()
  })
}
const showSystemSetting = () => {
  systemConfig.commit('SET_SETTING_DRAWER', true)
}
</script>
<style lang="less">
.user-dropdown-menu-wrapper {
  .ant-dropdown-menu-item {
    width: 100% !important;
  }
}
</style>
