import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { ListResult } from '@/services/types'
import { Setting, SettingUpdateInput } from './types'

export const getList = async (): Promise<Result<ListResult<Setting>>> => {
  const url = baseURL + '/api/admin/settings'
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const update = async (value: SettingUpdateInput): Promise<Result> => {
  const url = baseURL + '/api/admin/settings'
  var { status } = await clinet.put(url, value)
  if (status != 204) return Results.fail()
  return Results.ok()
}
