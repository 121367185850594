<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserCreateForm',
})
</script>
<template>
  <a-form :model="formModel" labelAlign="right" :labelCol="{ span: 6 }">
    <a-form-item label="用户名" name="userName" v-bind="form.validateInfos.userName" :rules="formRules.userName">
      <a-input v-model:value="formModel.userName" placeholder="请输入用户名" />
    </a-form-item>

    <a-form-item label="登录密码" name="password"  v-bind="form.validateInfos.password" :rules="formRules.password">
      <a-input-password v-model:value="formModel.password" placeholder="请输入登录密码" />
    </a-form-item>
  </a-form>
</template>
<script lang="ts" name="UserCreateForm" setup>
import { reactive, watch } from 'vue'
import { useForm } from 'ant-design-vue/es/form'
import { UserCreateInput } from '@/services/admin/user/types'

const props = defineProps<{
  value: UserCreateInput
}>()
const emits = defineEmits(['update:value'])

const formModel = reactive({ ...props.value })
const formRules = reactive({
  userName: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { max: 32, message: '用户名不能超过32个字符', trigger: 'change' },
    { pattern: /^[a-zA-Z0-9_]+$/, message: '用户名只能包含字母、数字和下划线', trigger: 'change' }
  ],
  password: [
    { required: true, message: '请输入登录密码', trigger: 'blur' },
    { max: 32, message: '登录密码不能超过32个字符', trigger: 'change' }
  ]
})
const form = useForm(formModel, formRules)

const validate = async (): Promise<boolean> => {
  try {
    await form.validate()
    return true
  } catch (e) {
    return false
  }
}
const resetFields = () => {
  form.resetFields()
}

watch(formModel, (val) => {
  emits('update:value', val)
})

defineExpose({
  validate,
  resetFields
})
</script>
<style lang="less" scoped></style>
