<template>
  <DataTable ref="dataTable" :columns="columns" :search-params="searchParams" :search-properties="searchProperties" :fetch="fetchData">
    <template #default="{ column, record }">
      <template v-if="column.dataIndex === 'payableAmount'">
        <span>{{ `￥${record.payableAmount}` }}</span>
      </template>
      <template v-else-if="column.dataIndex === 'actualAmount'">
        <span>{{ `￥${record.actualAmount}` }}</span>
      </template>
      <template v-else-if="column.dataIndex === 'paymentMethod'">
        <span>{{ `${record.payMethod.name}` }}</span>
      </template>
      <template v-else-if="column.dataIndex === 'isFinished'">
        <CheckCircleOutlined v-if="record.isFinished" style="color: #389e0d" />
        <SyncOutlined v-else :spin="true" style="color: #1677ff" />
      </template>
      <template v-else-if="column.dataIndex === 'remark'">
        <span v-if="record.failedReason" style="color: #ff4d4f">{{ record.failedReason }}</span>
        <span v-else>{{ record.orderNo }}</span>
      </template>
      <template v-else-if="column.dataIndex === 'createdOn'">
        <DateTimeLabel :value="record.createdOn" format="YY-MM-DD HH:mm:ss" />
      </template>
      <template v-else-if="column.dataIndex === 'modifiedOn'">
        <DateTimeLabel :value="record.modifiedOn" />
      </template>
      <template v-if="column.key === 'action'">
        <a-button type="link" :loading="currentRecordId == record.id" @click="fetchDepositRecord(record.id)">支付数据</a-button>
      </template>
    </template>
  </DataTable>

  <a-modal v-if="depositRecord" v-model:visible="isVisiblePaymentData" width="60%" :footer="null">
    <a-descriptions title="支付数据" :column="1">
      <a-descriptions-item label="订单标识">
        <router-link :to="{ name: 'OrderEdit', params: { id: depositRecord.orderId } }">
          {{ depositRecord.orderId }}
        </router-link>
      </a-descriptions-item>
      <a-descriptions-item label="支付类型">{{ depositRecord.paymentType }}</a-descriptions-item>
      <a-descriptions-item label="支付数据">{{ depositRecord.paymentData }}</a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>
<script lang="ts" setup>
import { ref, reactive, watchEffect, computed } from 'vue'
import { SyncOutlined, CheckCircleOutlined } from '@ant-design/icons-vue'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import { ChannelAccount, DepositRecordWithPaymentData, DepositRecordPagedQueryInput } from '@/services/admin/channelAccount/types'
import * as service from '@/services/admin/channelAccount'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
const props = defineProps<{
  value: ChannelAccount
}>()
const columns = [
  {
    title: '创建时间',
    dataIndex: 'createdOn',
    width: '15%',
    sorter: true
  },
  {
    title: '支付方式',
    dataIndex: 'paymentMethod',
    sorter: true
  },
  {
    title: '应付金额',
    dataIndex: 'payableAmount',
    sorter: true
  },
  {
    title: '实付金额',
    dataIndex: 'actualAmount',
    sorter: true
  },
  {
    title: '是否完成',
    dataIndex: 'isFinished',
    align: 'center',
    width: '6rem'
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: '20%',
    ellipsis: true
  },
  {
    title: '操作',
    key: 'action',
    align: 'center'
  }
]
const searchProperties = {
  payMethodId: {
    label: '支付方式',
    type: 'payMethod',
    options: {
      placeholder: '请选择支付方式'
    }
  },
  orderNo: {
    label: '订单号',
    type: 'input',
    options: {
      placeholder: '请输入订单号'
    }
  },
  payableAmount: {
    label: '下单金额',
    type: 'number',
    options: {
      placeholder: '请输入下单金额'
    }
  }
}
const dataTable = ref<DataTableInstance | null>(null)
const account = ref<ChannelAccount>(props.value)
const searchParams = reactive<DepositRecordPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10,
  accountId: account.value.id
})
const fetchData = (params) => {
  params.accountId = account.value.id
  return service.getDepositRecordList(params)
}
const depositRecord = ref<DepositRecordWithPaymentData | null>(null)
const isVisiblePaymentData = ref(false)
const currentRecordId = ref(null)
const fetchDepositRecord = async (id: string) => {
  currentRecordId.value = id
  const { value } = await service.getDepositRecord(id)
  currentRecordId.value = null
  if (value) {
    console.log(value)
    depositRecord.value = value
    isVisiblePaymentData.value = true
  }
}

watchEffect(() => {
  account.value = props.value
})
</script>
<style lang="less" scoped></style>
