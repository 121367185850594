<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <DataTable :columns="columns" :search-properties="searchProperties" :search-params="searchParams" :fetch="fetchData">
      <template #actions>
        <CreateButton @click="onCreate" />
      </template>
      <template #default="{ column, record }">
        <template v-if="column.key === 'commissionRate'">
          <span>{{ `${(record.commissionRate * 100).toFixed(2)} %` }}</span>
        </template>
        <template v-else-if="column.key === 'balance'">
          <span>{{ `￥${record.balance}` }}</span>
        </template>
        <template v-else-if="column.key === 'dailyCommission'">
          <span>{{ `￥${record.dailyCommission}` }}</span>
        </template>
        <template v-else-if="column.key === 'createdOn'">
          <DateTimeLabel :value="record.createdOn" />
        </template>
        <template v-else-if="column.key === 'modifiedOn'">
          <DateTimeLabel :value="record.modifiedOn" />
        </template>
        <template v-else-if="column.key === 'isEnabled'">
          <BooleanLabel :value="record.isEnabled" />
        </template>
        <template v-else-if="column.key === 'action'">
          <a-space>
            <EditButton type="link" @click="onEdit(record)" />
            <DeleteButton @delete="onDelete(record)" />
          </a-space>
        </template>
      </template>
    </DataTable>
  </a-flex>
</template>
<script lang="ts" name="TenantList" setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { Tenant, TenantPagedQueryInput, TenantWithChannels } from '@/services/admin/tenant/types'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import CreateButton from '@/components/Standards/CreateButton.vue'
import columns from './columns.json'
import searchProperties from './searchProperties.json'
import * as service from '@/services/admin/tenant'
import EditButton from '@/components/Standards/EditButton.vue'
import DeleteButton from '@/components/Standards/DeleteButton.vue'
import { notification } from 'ant-design-vue'

const router = useRouter()
const searchParams = reactive<TenantPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10,
  name: ''
})
const dataTable = ref<DataTableInstance>()
const fetchData = (params) => {
  return service.getList(params)
}
const onCreate = () => {
  router.push({ name: 'TenantCreate' })
}
const onEdit = (item: Tenant) => {
  router.push({ name: 'TenantEdit', params: { id: item.id } })
}
const onDelete = async (item: Tenant) => {
  const { isSuccess } = await service.remove(item.id)
  if (isSuccess) {
    notification.success({ message: '删除成功' })
    dataTable.value?.refresh()
  }
}
</script>
<style lang="less" scoped></style>
