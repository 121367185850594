import { RouteView } from '@/layouts'
import { RouteRecordRaw } from 'vue-router'

export const tenant: RouteRecordRaw = {
  path: '/',
  name: 'index',
  component: () => import('@/layouts/BasicLayout.vue'),
  meta: { title: '主页' },
  redirect: '/dashboard',
  children: [
    // dashboard
    {
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/views/tenant/dashboard/analysis.vue'),
      meta: { title: '系统主页', icon: 'bx-analyse', keepAlive: true }
    },
    {
      path: 'channels',
      name: 'TenantChannelList',
      component: () => import('@/views/tenant/channels/index.vue'),
      meta: { title: '充值通道', icon: 'bx-analyse' }
    },
    {
      path: 'accounts',
      name: 'TenantChannelAccountList',
      component: () => import('@/views/tenant/accounts/index.vue'),
      meta: { title: '充值账号', icon: 'bx-analyse' }
    },
    {
      path: 'accounts/edit/:id',
      name: 'TenantChannelAccountEdit',
      component: () => import('@/views/tenant/accounts/edit.vue'),
      meta: { title: '修改账号', icon: 'bx-analyse', hidden: true }
    },
    {
      path: 'orders',
      name: 'TenantOrderList',
      component: () => import('@/views/tenant/orders/index.vue'),
      meta: { title: '充值订单', icon: 'bx-analyse' }
    },
    {
      path: 'orders/edit/:id',
      name: 'TenantOrderEdit',
      component: () => import('@/views/tenant/orders/edit.vue'),
      meta: { title: '订单详情', icon: 'bx-analyse', hidden: true }
    },
    {
      path: 'verifier',
      name: 'TenantVerifierManage',
      redirect: '/verifier/list',
      meta: { title: '核销管理', icon: 'bx-analyse' },
      children: [
        {
          path: 'list',
          name: 'TenantVerifierList',
          component: () => import('@/views/tenant/verifiers/index.vue'),
          meta: { title: '核销列表', icon: 'bx-analyse' }
        },
        {
          path: 'groups',
          name: 'TenantVerifierGroupList',
          component: () => import('@/views/tenant/groups/index.vue'),
          meta: { title: '分组列表', icon: 'bx-analyse' }
        }
      ]
    },
    {
      path: 'statistics',
      name: 'TenantStatistics',
      component: () => import('@/views/tenant/statistics/index.vue'),
      meta: { title: '统计数据', icon: 'bx-analyse' }
    }
  ]
}
