<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DateTimeLabel',
})
</script>
<template>
  <label v-if="value">{{ dayjs(value).format(format) }}</label>
  <label v-else>/</label>
</template>
<script lang="ts" setup name="DateTimeLabel">
import dayjs from 'dayjs'
defineProps({
  value: {
    type: String || null,
    default: null
  },
  format: {
    type: String,
    default: 'YYYY-MM-DD HH:mm:ss'
  }
})
</script>
<style lang="less" scoped></style>
