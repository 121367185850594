<template>
  <a-space>
    <a-input-number v-if="isAdd" v-model:value="item" :min="0" :placeholder="'请输入金额'" />
    <a-flex>
      <a-button v-if="isAdd" type="link" @click="onAdd">确定</a-button>
      <a-button type="link" @click="() => (isAdd = !isAdd)">{{ isAdd ? '取消' : '添加' }}</a-button>
    </a-flex>

    <a-tag v-if="value" v-for="i in value" closable color="success" @close="onRemove(i)">{{ `￥${i}` }}</a-tag>
  </a-space>
</template>
<script lang="ts" setup>
import { ref, reactive, watch } from 'vue'
const props = defineProps<{
  value: number[] | null
}>()
const emits = defineEmits(['update:value'])
const value = reactive(props.value ?? [])
const isAdd = ref(false)
const item = ref(0)
const onAdd = () => {
  if (value.find((x) => x === item.value)) return
  value.push(item.value)
  emits('update:value', value)
}
const onRemove = (item: number) => {
  value.splice(value.indexOf(item), 1)
  console.debug(value)
  if (value.length === 0) emits('update:value', null)
  else emits('update:value', value)
}
</script>
<style lang="less" scoped></style>
