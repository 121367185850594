import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult, ListResult } from '@/services/types'
import { VerifierGroup, VerifierGroupCreateInput, VerifierGroupUpdateInput } from './types'

export const getList = async (): Promise<Result<ListResult<VerifierGroup>>> => {
  const url = baseURL + '/api/tenant/verifier-groups'
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const create = async (input: VerifierGroupCreateInput): Promise<Result<VerifierGroup>> => {
  const url = baseURL + '/api/tenant/verifier-groups'
  var { status, data } = await clinet.post(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const update = async (id: string, input: VerifierGroupUpdateInput): Promise<Result<VerifierGroup>> => {
  const url = baseURL + '/api/tenant/verifier-groups/' + id
  var { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const remove = async (id: string): Promise<Result> => {
  const url = baseURL + '/api/tenant/verifier-groups/' + id
  var { status } = await clinet.delete(url)
  if (status != 204) return Results.fail()
  return Results.ok()
}
