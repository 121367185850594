<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantChannelList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <a-card>
      <a-form :model="searchParams">
        <a-row :gutter="[16, 16]">
          <a-col :md="8" :sm="24">
            <a-form-item label="通道名称">
              <a-input v-model:value="searchParams.name" placeholder="请输入名称" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="支付方式">
              <a-select v-model:value="searchParams.payMethodId" placeholder="请选择支付方式" allowClear>
                <a-select-option v-for="item in payMethods" :key="item.id" :value="item.id">
                  <a-space>
                    <a-avatar :src="item.icon" shape="square" size="small" />
                    <span>{{ item.name }}</span>
                  </a-space>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-flex justify="start">
              <a-button type="primary" :loading="loading" @click="onSearch"><SearchOutlined />查询</a-button>
            </a-flex>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <a-skeleton v-if="!channels" active />
    <a-row v-else :gutter="[16, 16]">
      <a-col v-for="item in channels" :lg="6" :md="8" :sm="24">
        <ChannelCard :value="item" @action="onAction" />
      </a-col>
    </a-row>
  </a-flex>
  <a-modal v-model:open="isVisibleQrCode" width="60%" :footer="null">
    <ChannelQrCode v-if="currentChannel" :channel="currentChannel" :payment-domain="paymentDomain" :tenant-id="value.id" :nonce="nonce" />
  </a-modal>
  <a-modal v-model:open="isVisibleDocument" width="60%" title="资源文档" :footer="null">
    <ChannelResourceDocument v-if="currentChannel" :description-url="currentChannel.resourceDescriptionFullUrl" v-model:visible="isVisibleDocument" />
  </a-modal>
</template>
<script lang="ts" name="TenantChannelList" setup>
import { ref, reactive, onMounted, watchEffect } from 'vue'
import { notification } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { SearchOutlined } from '@ant-design/icons-vue'
import ChannelCard from '@/views/tenant/channels/ChannelCard.vue'
import ChannelQrCode from '@/views/tenant/channels/ChannelQrCode.vue'
import ChannelResourceDocument from '@/views/tenant/channels/ChannelResourceDocument.vue'
import { PayMethod } from '@/services/admin/payMethod/types'
import { Channel, ChannelPagedQueryInput, ChannelWithPayMethods } from '@/services/admin/channel/types'
import { TenantChannel, TenantWithChannels } from '@/services/admin/tenant/types'
import * as settingService from '@/services/admin/settings'
import * as payMethodService from '@/services/admin/payMethod'
import * as tenantlService from '@/services/admin/tenant'
import * as channelService from '@/services/admin/channel'

const props = defineProps<{
  value: TenantWithChannels
}>()
const searchParams = reactive<ChannelPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 100,
  tenantId: props.value.id
})
const loading = ref(false)
const payMethods = ref<PayMethod[]>([])
const channels = ref<ChannelWithPayMethods[]>()
const nonces = ref<TenantChannel[]>()
const onSearch = async () => {
  await fetchData()
}
const onAction = async (key: string, item: ChannelWithPayMethods) => {
  switch (key) {
    case 'qrcode':
      onDisplayQrCode(item)
      break
    case 'document':
      onDisplayDocument(item)
      break
    case 'download':
      if (item.resourceUrl) {
        window.open(item.resourceUrl)
      } else {
        notification.info({ message: '此通道尚未提供资源文件！' })
      }
  }
}

const currentChannel = ref<ChannelWithPayMethods>()

const paymentDomain = ref<string | null>(null)
const getPaymentDomain = async () => {
  const { value } = await settingService.getList()
  if (value) {
    paymentDomain.value = value.items.find((x) => x.name == 'OrderStore.PaymentDomain').value
  }
}
const nonce = ref<string | null>(null)
const getNonce = async () => {
  const tc = nonces.value.find((x) => x.channelId == currentChannel.value.id)
  nonce.value = tc.nonce
}

const isVisibleQrCode = ref(false)
const onDisplayQrCode = async (record: ChannelWithPayMethods) => {
  currentChannel.value = record
  getNonce()
  isVisibleQrCode.value = true
}

const isVisibleDocument = ref(false)
const onDisplayDocument = (record: ChannelWithPayMethods) => {
  currentChannel.value = record
  isVisibleDocument.value = true
}

const fetchData = async () => {
  loading.value = true
  const { value } = await channelService.getList(searchParams)
  loading.value = false
  if (value) {
    channels.value = value.items
  }
}
const loadPayMehtods = async () => {
  const { value } = await payMethodService.getList({ maxResultCount: 100 })
  if (value) {
    payMethods.value = value.items
  }
}
const loadTenantChannels = async () => {
  const { value } = await tenantlService.getChannelList(props.value.id)
  if (value) {
    nonces.value = value.items
  }
}

watchEffect(() => {
  searchParams.tenantId = props.value.id
})
onMounted(async () => {
  await loadPayMehtods()
  await fetchData()
  await getPaymentDomain()
  await loadTenantChannels()
})
</script>
<style lang="less" scoped></style>
