<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantChannelList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <a-card>
      <a-form :model="searchParams">
        <a-row :gutter="[16, 16]">
          <a-col :md="8" :sm="24">
            <a-form-item label="通道名称">
              <a-input v-model:value="searchParams.name" placeholder="请输入名称" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="支付方式">
              <PayMethodSelect v-model:value="searchParams.payMethodId" placeholder="请选择支付方式" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-flex justify="start">
              <a-button type="primary" :loading="loading" @click="onSearch"><SearchOutlined />查询</a-button>
            </a-flex>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <a-skeleton v-if="!channels" active />
    <a-row v-else :gutter="[16, 16]">
      <a-col v-for="item in channels" :lg="6" :md="8" :sm="24">
        <ChannelCard :value="item" :qrCodeLoading="noneLoading" @action="onAction" />
      </a-col>
    </a-row>
  </a-flex>
  <a-modal v-model:open="isVisibleQrCode" width="60%" :footer="null">
    <ChannelQrCode v-if="currentChannel" :channel="currentChannel" :payment-domain="paymentDomain" :tenant-id="channelWithNonce.tenantId" :nonce="channelWithNonce.nonce" :edit="true" />
  </a-modal>
  <a-modal v-model:open="isVisibleDocument" width="60%" title="资源文档" :footer="null">
    <ChannelResourceDocument v-if="currentChannel" :description-url="currentChannel.resourceDescriptionUrl" v-model:visible="isVisibleDocument" />
  </a-modal>
</template>
<script lang="ts" name="TenantChannelList" setup>
import { ref, reactive, onMounted } from 'vue'
import { Channel, ChannelPagedQueryInput, ChannelWithNonce, PayMethod } from '@/services/tenant/channel/types'
import ChannelCard from './ChannelCard.vue'
import ChannelResourceDocument from './ChannelResourceDocument.vue'
import PayMethodSelect from '@/components/Standards/PayMethodSelect.vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import ChannelQrCode from './ChannelQrCode.vue'
import { notification } from 'ant-design-vue'
import { getSettings } from '@/services/tenant'
import * as service from '@/services/tenant/channel'

const searchParams = reactive<ChannelPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})
const loading = ref(false)
const channels = ref<Channel[]>()
const onSearch = async () => {
  await fetchData()
}
const onAction = async (key: string, item: Channel) => {
  switch (key) {
    case 'qrcode':
      onDisplayQrCode(item)
      break
    case 'document':
      onDisplayDocument(item)
      break
    case 'download':
      if (item.resourceUrl) {
        window.open(item.resourceUrl)
      } else {
        notification.info({ message: '此通道尚未提供资源文件！' })
      }
  }
}
const currentChannel = ref<Channel>()
const paymentDomain = ref<string | null>(null)
const getPaymentDomain = async () => {
  const { value } = await getSettings()
  if (value && value['paymentDomain']) {
    paymentDomain.value = value['paymentDomain'].trim().replace(/\/+$/, '')
  }
}

const noneLoading = ref(false)
const channelWithNonce = ref<ChannelWithNonce>()
const getNonce = async () => {
  noneLoading.value = true
  const { value } = await service.get(currentChannel.value.id)
  noneLoading.value = false
  channelWithNonce.value = value
}

const isVisibleQrCode = ref(false)
const onDisplayQrCode = async (record: Channel) => {
  currentChannel.value = record
  await getNonce()
  isVisibleQrCode.value = true
}

const isVisibleDocument = ref(false)
const onDisplayDocument = (record: Channel) => {
  currentChannel.value = record
  isVisibleDocument.value = true
}

const fetchData = async () => {
  loading.value = true
  const { value } = await service.getList(searchParams)
  loading.value = false
  if (value) {
    channels.value = value.items
  }
}

onMounted(async () => {
  await fetchData()
  await getPaymentDomain()
})
</script>
<style lang="less" scoped></style>
