import { RouteView } from '@/layouts'
import { Router } from './types'
import { RouteRecordRaw } from 'vue-router'

export const admin: RouteRecordRaw = {
  path: '/',
  name: 'index',
  component: () => import('@/layouts/BasicLayout.vue'),
  meta: { title: '主页' },
  redirect: '/dashboard',
  children: [
    // dashboard
    {
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/views/admin/dashboard/analysis.vue'),
      meta: { title: '系统主页', icon: 'bx-analyse', keepAlive: true, permission: ['admin'] }
    },
    // channelModule
    {
      path: 'channel-module',
      component: RouteView,
      redirect: '/channel-module/channel/list',
      name: 'Channels',
      meta: { title: '支付通道', icon: 'bx-analyse' },
      children: [
        {
          path: 'pay-method/list',
          name: 'PayMethodList',
          component: () => import('@/views/admin/channelModule/payMethods/index.vue'),
          meta: { title: '支付方式' }
        },
        {
          path: 'channel/list',
          name: 'ChannelList',
          component: () => import('@/views/admin/channelModule/channels/index.vue'),
          meta: { title: '通道列表' }
        },
        {
          path: 'channel/create',
          name: 'ChannelCreate',
          component: () => import('@/views/admin/channelModule/channels/create.vue'),
          meta: { title: '创建通道', hidden: true }
        },
        {
          path: 'channel/edit/:id',
          name: 'ChannelEdit',
          component: () => import('@/views/admin/channelModule/channels/edit.vue'),
          meta: { title: '修改通道', hidden: true }
        },
        {
          path: 'channel-account/list',
          name: 'ChannelAccountList',
          component: () => import('@/views/admin/channelModule/channelAccounts/index.vue'),
          meta: { title: '通道账号' }
        },
        {
          path: 'channel-account/edit/:id',
          name: 'ChannelAccountEdit',
          component: () => import('@/views/admin/channelModule/channelAccounts/edit.vue'),
          meta: { title: '账号详情', hidden: true }
        }
      ]
    },
    {
      path: 'tenant-module',
      component: RouteView,
      redirect: '/tenant-module/tenant/list',
      name: 'Tenants',
      meta: { title: '商家管理', icon: 'bx-analyse' },
      children: [
        {
          path: 'tenant/list',
          name: 'TenantList',
          component: () => import('@/views/admin/tenantModule/tenants/index.vue'),
          meta: { title: '商家列表' }
        },
        {
          path: 'tenant/create',
          name: 'TenantCreate',
          component: () => import('@/views/admin/tenantModule/tenants/create.vue'),
          meta: { title: '创建商家', hidden: true }
        },
        {
          path: 'tenant/edit/:id',
          name: 'TenantEdit',
          component: () => import('@/views/admin/tenantModule/tenants/edit.vue'),
          meta: { title: '修改商家', hidden: true }
        },
        {
          path: 'verifier/list',
          name: 'VerifierList',
          component: () => import('@/views/admin/tenantModule/verifiers/index.vue'),
          meta: { title: '核销列表' }
        },
        {
          path: 'order/list',
          name: 'OrderList',
          component: () => import('@/views/admin/tenantModule/orders/index.vue'),
          meta: { title: '订单列表' }
        },
        {
          path: 'order/edit/:id',
          name: 'OrderEdit',
          component: () => import('@/views/admin/tenantModule/orders/edit.vue'),
          meta: { title: '订单详情', hidden: true }
        }
      ]
    },

    // authModule
    {
      path: 'user-module',
      component: RouteView,
      redirect: '/user-module/user/list',
      name: 'Users',
      meta: { title: '认证模块', icon: 'bx-analyse' },
      children: [
        {
          path: 'user/list',
          name: 'UserList',
          component: () => import('@/views/admin/authModule/users/index.vue'),
          meta: { title: '用户列表' }
        },
        {
          path: 'role/list',
          name: 'RoleList',
          component: () => import('@/views/admin/authModule/roles/index.vue'),
          meta: { title: '角色列表' }
        }
      ]
    },
    {
      path: 'settings',
      component: () => import('@/views/admin/settings/index.vue'),
      name: 'Settings',
      meta: { title: '系统设置', icon: 'bx-analyse' }
    }
  ]
}
