<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChannelPayMethodSelect',
})
</script>
<template>
  <a-select v-model:value="selecteds" mode="multiple" placeholder="请选择支付方式" style="width: 100%">
    <a-select-option v-for="item in options" :key="item.id" :value="item.id" :label="item.name">
      <a-flex gap="small">
        <a-avatar :src="item.iconFullUrl" shape="square" size="small">
          <template #icon><AntDesignOutlined /></template>
        </a-avatar>
        <span>{{ item.name }}</span>
      </a-flex>
    </a-select-option>
  </a-select>
</template>
<script lang="ts" name="ChannelPayMethodSelect" setup>
import { ref, reactive, onMounted, watch } from 'vue'
import { AntDesignOutlined } from '@ant-design/icons-vue'
import * as service from '@/services/admin/payMethod'

const props = defineProps<{
  value: Array<string>
}>()
const emits = defineEmits(['update:value'])
// const map = (methods: Array<string>): string => {
//   return methods.length > 0 ? methods[0] : ''
// }
const options = ref([])
//const selected = ref(map(props.value))
const selecteds = ref([...props.value])
const fetchData = async () => {
  const { value } = await service.getList({ maxResultCount: 100 })
  options.value = value.items
}
watch(
  () => selecteds.value,
  (val) => {
    emits('update:value', val)
  }
)
watch(
  () => props.value,
  (val) => {
    selecteds.value = val
    //selected.value = map(val)
  }
)
onMounted(() => {
  fetchData()
})
</script>
<style lang="less" scoped></style>
