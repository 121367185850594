<template>
  <a-space>
    <a-upload :disabled="loading" :show-upload-list="false" :multiple="false" :customRequest="uploadFile">
      <a-button :disabled="loading">
        <LoadingOutlined v-if="loading" :span="true" />
        <UploadOutlined v-else />
        上传
      </a-button>
    </a-upload>
    <span v-if="value"><PaperClipOutlined /> {{ value }}</span>
  </a-space>
</template>
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import * as service from '@/services/upload'
import { PaperClipOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons-vue'
const props = defineProps<{
  value: string
}>()
const emits = defineEmits(['update:value'])
const resource = ref(props.value)
const loading = ref(false)
const uploadFile = async (data) => {
  let fileName = data.file.name
  const extension = fileName.split('.').pop()
  fileName = `${Date.now()}.${extension}`

  loading.value = true
  const { isSuccess, value } = await service.upload({
    file: data.file,
    fileName: `channel/resources/${fileName}`
  })
  loading.value = false
  if (isSuccess) {
    resource.value = value.fileName
    emits('update:value', value.fileName)
  }
}
</script>
<style lang="less" scoped></style>
