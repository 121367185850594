<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'VerifierList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <DataTable ref="dataTable" :columns="columns" :search-properties="searchProperties" :search-params="searchParams" :fetch="fetchData">
      <template #actions>
        <CreateButton @click="onCreate" />
      </template>
      <template #default="{ column, record }">
        <template v-if="column.key === 'tenantId'">
          <span>{{ record.tenant.name }}</span>
        </template>
        <template v-else-if="column.key === 'id'">
          <a-popover v-if="record.remark" title="备注">
            <template #content>
              <p>{{ record.remark }}</p>
            </template>
            <span>{{ record.user.userName }}</span>
          </a-popover>
          <span v-else>{{ record.user.userName }}</span>
        </template>
        <template v-else-if="column.key === 'groupId'">
          <span>{{ record.group.name }}</span>
        </template>
        <template v-else-if="column.key === 'dailyDeposit'">
          <span>{{ `￥${record.dailyDeposit}` }}</span>
        </template>
        <template v-else-if="column.key === 'createdOn'">
          <DateTimeLabel :value="record.createdOn" />
        </template>
        <template v-else-if="column.key === 'modifiedOn'">
          <DateTimeLabel :value="record.modifiedOn" />
        </template>
        <template v-else-if="column.key === 'isEnabled'">
          <BooleanLabel :value="record.isEnabled" />
        </template>
        <template v-else-if="column.key === 'action'">
          <a-space>
            <EditButton type="link" @click="onEdit(record)" />
            <DeleteButton @delete="onDelete(record)" />
          </a-space>
        </template>
      </template>
    </DataTable>
  </a-flex>
  <VerifierCreateDrawer v-model:visible="isVisibleCreate" @submitted="onSaveSucceed" />
  <VerifierUpdateDrawer v-if="currentRecord" v-model:visible="isVisibleEdit" :value="currentRecord" @submitted="onSaveSucceed" />
</template>
<script lang="ts" name="VerifierList" setup>
import { ref, reactive } from 'vue'
import { notification } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import CreateButton from '@/components/Standards/CreateButton.vue'
import columns from './columns.json'
import searchProperties from './searchProperties.json'
import * as service from '@/services/admin/verifier'
import EditButton from '@/components/Standards/EditButton.vue'
import DeleteButton from '@/components/Standards/DeleteButton.vue'
import { Verifier, VerifierPagedQueryInput } from '@/services/admin/verifier/types'
import VerifierCreateDrawer from './VerifierCreateDrawer.vue'
import VerifierUpdateDrawer from './VerifierUpdateDrawer.vue'

const searchParams = reactive<VerifierPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})
const dataTable = ref<DataTableInstance>()
const fetchData = (params) => {
  return service.getList(params)
}
const isVisibleCreate = ref(false)
const onCreate = () => {
  isVisibleCreate.value = true
}
const onSaveSucceed = () => {
  console.debug('onCreateSucceed')
  dataTable.value?.refresh()
}
const currentRecord = ref<Verifier | null>(null)
const isVisibleEdit = ref(false)
const onEdit = (item: Verifier) => {
  currentRecord.value = item
  isVisibleEdit.value = true
}
const onDelete = async (item: Verifier) => {
  currentRecord.value = item
  const { isSuccess } = await service.remove(item.id)
  if (isSuccess) {
    notification.success({ message: '删除成功' })
    dataTable.value?.refresh()
  }
}
</script>
<style lang="less" scoped></style>
