import { RouteRecordRaw } from 'vue-router'
export const verifier: RouteRecordRaw = {
  path: '/',
  name: 'index',
  component: () => import('@/layouts/BasicLayout.vue'),
  meta: { title: '主页' },
  redirect: '/dashboard',
  children: [
    // dashboard
    {
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/views/verifier/dashhoard/analysis.vue'),
      meta: { title: '系统主页', icon: 'bx-analyse', keepAlive: true }
    },
    {
      path: 'accounts',
      name: 'VerifierChannelAccountList',
      component: () => import('@/views/verifier/accounts/index.vue'),
      meta: { title: '账号管理', icon: 'bx-analyse' }
    },
    {
      path: 'accounts/:id/edit',
      name: 'VerifierChannelAccountEdit',
      component: () => import('@/views/verifier/accounts/edit.vue'),
      meta: { title: '修改账号', icon: 'bx-analyse', hidden: true }
    },
    {
      path: 'records',
      name: 'VerifierDepositRecordList',
      component: () => import('@/views/verifier/records/index.vue'),
      meta: { title: '充值记录', icon: 'bx-analyse' }
    },
    {
      path: 'statistics',
      name: 'VerifierStatistics',
      component: () => import('@/views/verifier/statistics/index.vue'),
      meta: { title: '统计数据', icon: 'bx-analyse' }
    }
  ]
}
