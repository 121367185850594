import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult } from '@/services/types'
import { Verifier, VerifierCreateInput, VerifierExternalCreateInput, VerifierPagedQueryInput, VerifierUpdateInput } from './types'

export const map = (verifier: Verifier): VerifierUpdateInput => {
  return {
    groupId: verifier.group?.id,
    isEnabled: verifier.isEnabled,
    remark: verifier.remark
  }
}

export const get = async (id: string): Promise<Result<Verifier>> => {
  const url = `${baseURL}/api/tenant/verifiers/${id}`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (query: VerifierPagedQueryInput): Promise<Result<PagedResult<Verifier>>> => {
  const url = baseURL + '/api/tenant/verifiers'
  var { status, data } = await clinet.get(url, { params: query })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const create = async (input: VerifierCreateInput): Promise<Result<Verifier>> => {
  const url = baseURL + '/api/tenant/verifiers'
  var { status, data } = await clinet.post(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const update = async (id: string, input: VerifierUpdateInput): Promise<Result<Verifier>> => {
  const url = baseURL + '/api/tenant/verifiers/' + id
  var { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const remove = async (id: string): Promise<Result> => {
  const url = baseURL + '/api/tenant/verifiers/' + id
  var { status } = await clinet.delete(url)
  if (status != 204) return Results.fail()
  return Results.ok()
}

export const createExternal = async (input: VerifierExternalCreateInput): Promise<Result<Verifier>> => {
  const url = baseURL + '/api/tenant/verifiers/external'
  var { status, data } = await clinet.post(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const updateExternal = async (id: string, input: VerifierExternalCreateInput): Promise<Result<Verifier>> => {
  const url = baseURL + '/api/tenant/verifiers/external/' + id
  var { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
