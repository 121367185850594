<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantStatistics',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <a-card>
      <a-row :gutter="24">
        <a-col :md="12">
          <a-form-item label="时间范围">
            <a-range-picker :locale="zhCN" v-model:value="timeRange" />
          </a-form-item>
        </a-col>
        <a-col :md="12" style="align-items: flex-end">
          <a-flex justify="end">
            <a-button type="primary" @click="fetchTenantData">查询</a-button>
          </a-flex>
        </a-col>
      </a-row>
    </a-card>
    <a-table :columns="tenantColumns" :data-source="tenantPagedList.items" :pagination="tenantPagination" :loading="isTenantFetching" @change="onTenantTableChange" @expand="onExpand">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'createdOn'">
          <DateTimeLabel :value="record.createdOn" />
        </template>
      </template>
      <template #expandedRowRender>
        <a-tabs v-model:activeKey="activeTabKey">
          <a-tab-pane key="channel" tab="通道统计" style="width: 100%">
            <a-table :columns="channelColumns" :data-source="channelPagedList.items" :pagination="channelPagination" :loading="isChannelFetching" @change="onChannelTableChange">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'channel'">
                  <span>{{ record.channel.name }}</span>
                </template>
              </template>
            </a-table>
          </a-tab-pane>

          <a-tab-pane key="verifier" tab="核销统计" style="width: 100%">
            <a-table :columns="verifierColumns" :data-source="verifierPagedList.items" :pagination="verifierPagination" :loading="isVerifierFetching" @change="onVerifierTableChange">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'user'">
                  <span>{{ record.user.userName }}</span>
                </template>
              </template>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </template>
    </a-table>
  </a-flex>
</template>
<script lang="ts" name="TenantStatistics" setup>
import type { Dayjs } from 'dayjs'
import { ref, reactive, computed, onMounted, watch } from 'vue'
import zhCN from 'ant-design-vue/es/date-picker/locale/zh_CN'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import { PagedResult } from '@/services/types'
import {
  TenantDailyStatistic,
  TenantDailyStatisticPagedQueryInput,
  ChannelDailyStatistic,
  ChannelDailyStatisticPagedQueryInput,
  VerifierDailyStatistic,
  VerifierDailyStatisticPagedQueryInput
} from '@/services/tenant/statistics/types'
import tenantColumns from './tenantColumns.json'
import channelColumns from './channelColumns.json'
import verifierColumns from './verifierColumns.json'
import * as service from '@/services/tenant/statistics/index'
import { toUpperCamelCase } from '@/utils/util'

type RangeValue = [Dayjs, Dayjs]
const timeRange = ref<RangeValue>()
const activeTabKey = ref('channel')
const tenantSearchParams = reactive<TenantDailyStatisticPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})
const channelSearchParams = reactive<ChannelDailyStatisticPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})
const verifierSearchParams = reactive<VerifierDailyStatisticPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})

const tenantPagedList = ref<PagedResult<TenantDailyStatistic>>({ items: [], totalCount: 0 })
const channelPagedList = ref<PagedResult<ChannelDailyStatistic>>({ items: [], totalCount: 0 })
const verifierPagedList = ref<PagedResult<VerifierDailyStatistic>>({ items: [], totalCount: 0 })
const tenantPagination = computed(() => {
  return {
    total: tenantPagedList.value.totalCount,
    current: tenantSearchParams.skipCount / tenantSearchParams.maxResultCount + 1,
    pageSize: tenantSearchParams.maxResultCount,
    showTotal: (total) => `共 ${total} 条`
  }
})
const channelPagination = computed(() => {
  return {
    total: channelPagination.value?.totalCount ?? 0,
    current: channelSearchParams.skipCount / tenantSearchParams.maxResultCount + 1,
    pageSize: channelSearchParams.maxResultCount,
    showTotal: (total) => `共 ${total} 条`
  }
})
const verifierPagination = computed(() => {
  return {
    total: verifierPagedList.value?.totalCount ?? 0,
    current: verifierSearchParams.skipCount / verifierSearchParams.maxResultCount + 1,
    pageSize: verifierSearchParams.maxResultCount,
    showTotal: (total) => `共 ${total} 条`
  }
})

const isTenantFetching = ref(false)
const fetchTenantData = async () => {
  isTenantFetching.value = true
  const { value } = await service.getTenantStatistics(tenantSearchParams)
  isTenantFetching.value = false
  if (value) {
    tenantPagedList.value = value
  }
}
const isChannelFetching = ref(false)
const fetchChannelData = async () => {
  isChannelFetching.value = true
  const { value } = await service.getChannelStatistics(tenantSearchParams)
  isChannelFetching.value = false
  if (value) {
    channelPagedList.value = value
  }
}
const isVerifierFetching = ref(false)
const fetchVerifierData = async () => {
  isVerifierFetching.value = true
  const { value } = await service.getVerifierStatistics(tenantSearchParams)
  isVerifierFetching.value = false
  if (value) {
    verifierPagedList.value = value
  }
}

const onExpand = (expanded, record) => {
  if (expanded) {
    channelSearchParams.skipCount = 0
    channelSearchParams.startDate = record.date
    channelSearchParams.endDate = record.date
    fetchChannelData()
    verifierSearchParams.skipCount = 0
    channelSearchParams.startDate = record.date
    channelSearchParams.endDate = record.date
    fetchVerifierData()
  }
}

const onTenantTableChange = async (pag, _filters, sorter) => {
  if (pag) {
    tenantSearchParams.maxResultCount = pag.pageSize
    tenantSearchParams.skipCount = (pag.current - 1) * pag.pageSize
  }
  if (sorter?.field) {
    const field = toUpperCamelCase(sorter.field)
    const order = sorter.order === 'ascend' ? 'ASC' : 'DESC'
    tenantSearchParams.sorting = `${field} ${order}`
  }
  await fetchTenantData()
}

const onChannelTableChange = async (pag, _filters, sorter) => {
  if (pag) {
    channelSearchParams.maxResultCount = pag.pageSize
    channelSearchParams.skipCount = (pag.current - 1) * pag.pageSize
  }
  if (sorter?.field) {
    const field = toUpperCamelCase(sorter.field)
    const order = sorter.order === 'ascend' ? 'ASC' : 'DESC'
    channelSearchParams.sorting = `${field} ${order}`
  }
  await fetchChannelData()
}

const onVerifierTableChange = async (pag, _filters, sorter) => {
  if (pag) {
    verifierSearchParams.maxResultCount = pag.pageSize
    verifierSearchParams.skipCount = (pag.current - 1) * pag.pageSize
  }
  if (sorter?.field) {
    const field = toUpperCamelCase(sorter.field)
    const order = sorter.order === 'ascend' ? 'ASC' : 'DESC'
    verifierSearchParams.sorting = `${field} ${order}`
  }
  await fetchVerifierData()
}

watch(timeRange, (val) => {
  tenantSearchParams.startDate = val?.[0]?.format('YYYY-MM-DD')
  tenantSearchParams.endDate = val?.[1]?.format('YYYY-MM-DD')
})
onMounted(() => {
  fetchTenantData()
})
</script>
<style lang="less" scoped></style>
