<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChannelCreate',
})
</script>
<template>
  <a-flex vertical>
    <a-card title="新建充值通道">
      <a-form :model="formModel" :rules="formRules" labelAlign="right" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14 }">
        <a-form-item label="图标">
          <ImageUpload v-model:value="formModel.icon" folder="channel/icon" />
        </a-form-item>

        <a-form-item v-bind="form.validateInfos.name" label="名称" name="name" :rules="formRules.name">
          <a-input v-model:value="formModel.name" placeholder="请输入名称" show-count :maxlength="32" />
        </a-form-item>

        <a-form-item v-bind="form.validateInfos.description" label="描述" name="description" :rules="formRules.description">
          <a-textarea v-model:value="formModel.description" placeholder="请输入描述" show-count :maxlength="256" />
        </a-form-item>

        <a-form-item v-bind="form.validateInfos.payMethods" label="支付方式" name="payMethods" :rules="formRules.payMethods">
          <ChannelPayMethodSelect v-model:value="formModel.payMethods" />
        </a-form-item>

        <a-form-item label="最大下单金额">
          <a-input-number v-model:value="formModel.maxDepositAmount" :min="1" :step="100" placeholder="请输入最大下单金额" allowClear style="width: 11rem" />
        </a-form-item>

        <a-form-item label="固定支付金额">
          <FixedAmountList v-model:value="formModel.fixedAmountList" />
        </a-form-item>

        <a-form-item label="支付过期">
          <a-time-picker v-model:value="formModel.maxDepositTime" placeholder="请选择支付过期时间" valueFormat="HH:mm:ss" :showNow="false" allowClear style="width: 11rem">
            <template #renderExtraFooter>
              <a-space>
                <a-button size="small" type="link" @click="formModel.maxDepositTime = '00:01:00'">1分钟</a-button>
                <a-button size="small" type="link" @click="formModel.maxDepositTime = '00:03:00'">3分钟</a-button>
                <a-button size="small" type="link" @click="formModel.maxDepositTime = '00:05:00'">5分钟</a-button>
              </a-space>
            </template>
          </a-time-picker>
        </a-form-item>

        <a-form-item label="资源文件" name="resourceUrl">
          <ChannelResourceUpload v-model:value="formModel.resourceUrl" />
        </a-form-item>

        <a-form-item label="资源文档">
          <Editor ref="editor" v-model:value="descriptionHtml" />
        </a-form-item>

        <a-form-item :wrapperCol="{ offset: 6 }">
          <a-space>
            <a-button type="primary" :loading="isSubmitting" @click="onSubmit">创建</a-button>
            <a-button @click="onReset">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-card>
  </a-flex>
</template>
<script lang="ts" name="ChannelCreate" setup>
import { ref, reactive } from 'vue'
import { notification } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { useForm } from 'ant-design-vue/es/form'
import { ChannelCreateInput } from '@/services/admin/channel/types'
import ImageUpload from '@/components/Standards/ImageUpload.vue'
import { EditorInstance } from '@/components/Standards/Editor/types'
import Editor from '@/components/Standards/Editor/index.vue'
import ChannelPayMethodSelect from './ChannelPayMethodSelect.vue'
import * as service from '@/services/admin/channel'
import { upload } from '@/services/upload'
import ChannelResourceUpload from './ChannelResourceUpload.vue'
import FixedAmountList from './FixedAmountList.vue'

const router = useRouter()
const formModel: ChannelCreateInput = reactive({
  name: '',
  description: '',
  icon: null,
  resourceUrl: null,
  resourceDescriptionUrl: null,
  payMethods: []
})
const formRules = reactive({
  name: [
    { required: true, message: '请输入名称', trigger: 'blur' },
    { max: 32, message: '名称不能超过32个字符', trigger: 'change' }
  ],
  description: [{ max: 256, message: '描述不能超过256个字符', trigger: 'change' }],
  payMethods: [{ type: 'array', required: true, message: '请选择至少一种支付方式', trigger: 'change' }]
})

const form = useForm(formModel, formRules)

const editor = ref<EditorInstance>()
const descriptionHtml = ref('')
const isSubmitting = ref(false)
const onSubmit = async () => {
  try {
    const res = await form.validate()
  } catch (e) {
    return
  }

  isSubmitting.value = true
  if (editor.value.hasChanged) {
    const fileName = `${Date.now()}.html`
    var htmlFile = new File([descriptionHtml.value], fileName, { type: 'text/html' })
    const { isSuccess, value } = await upload({ file: htmlFile, fileName: `editor/html/${fileName}` })
    if (!isSuccess) {
      notification.error({ message: '资源文档上传失败！' })
      return
    }
    formModel.resourceDescriptionUrl = value.fileName
  }

  const { isSuccess } = await service.create(formModel)
  isSubmitting.value = false
  if (isSuccess) {
    notification.success({ message: '创建成功' })
    form.resetFields()
    router.push({ name: 'ChannelList' })
  }
}
const onReset = () => {
  form.resetFields()
}
</script>
<style lang="less" scoped></style>
