<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PayMethodCreateDrawer',
})
</script>
<template>
  <a-drawer v-bind="$attrs" v-model:open="open" title="新建充值渠道">
    <a-form :model="formModel" labelAlign="right" :labelCol="{ span: 6 }">
      <a-form-item label="图标">
        <ImageUpload v-model:value="formModel.icon" folder="payMethod/icon" />
      </a-form-item>
      <a-form-item label="标识" name="id" :rules="formRules.id" v-bind="form.validateInfos.id">
        <a-input v-model:value="formModel.id" placeholder="请输入标识" show-count :maxlength="32" />
      </a-form-item>
      <a-form-item label="名称" name="name" :rules="formRules.name" v-bind="form.validateInfos.name">
        <a-input v-model:value="formModel.name" placeholder="请输入名称" show-count :maxlength="32" />
      </a-form-item>
      <a-form-item label="描述" name="description" :rules="formRules.description" v-bind="form.validateInfos.description">
        <a-textarea v-model:value="formModel.description" placeholder="请输入描述" show-count :maxlength="256" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-flex justify="end" gap="small">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :loading="isCreating" @click="onSubmit">创建</a-button>
      </a-flex>
    </template>
  </a-drawer>
</template>
<script lang="ts" name="PayMethodCreateDrawer" setup>
import { ref, reactive, UnwrapRef, watch } from 'vue'
import { PayMethodCreateInput } from '@/services/admin/payMethod/types'
import { Form, notification } from 'ant-design-vue'
import * as service from '@/services/admin/payMethod'
import ImageUpload from '@/components/Standards/ImageUpload.vue'
const props = defineProps<{
  visible: boolean
}>()
const emits = defineEmits(['update:visible', 'submitted'])
const useForm = Form.useForm
const open = ref<boolean>(props.visible)

const isCreating = ref(false)
const formModel: UnwrapRef<PayMethodCreateInput> = reactive({
  id: '',
  name: '',
  description: '',
  icon: null
})
const formRules = reactive({
  id: [
    { required: true, message: '请输入标识', trigger: 'blur' },
    { max: 32, message: '标识不能超过32个字符', trigger: 'change' },
    { pattern: /^[a-zA-Z0-9_]+$/, message: '标识只能包含字母、数字和下划线', trigger: 'change' }
  ],
  name: [
    { required: true, message: '请输入名称', trigger: 'blur' },
    { max: 32, message: '名称不能超过32个字符', trigger: 'change' }
  ],
  description: [{ max: 256, message: '描述不能超过256个字符', trigger: 'change' }]
})
const form = useForm(formModel, formRules)
const onSubmit = async () => {
  try {
    await form.validate()
  } catch {
    return
  }

  isCreating.value = true
  const { isSuccess } = await service.create(formModel)
  isCreating.value = false
  if (isSuccess) {
    notification.success({ message: '创建成功' })
    form.resetFields()
    open.value = false
    emits('submitted')
  }
}
const onCancel = () => {
  open.value = false
}

watch(
  () => props.visible,
  (val) => {
    open.value = val
  }
)
watch(
  () => open.value,
  (val) => {
    emits('update:visible', val)
  }
)
</script>
<style lang="less" scoped></style>
