<template>
  <div>
    <a-skeleton v-if="!documentHtml" active />
    <div v-else v-html="documentHtml"></div>
  </div>
</template>
<script lang="ts" setup>
import clinet from '@/utils/http/axios'
import { ref, watchEffect, watch } from 'vue'
const props = defineProps<{
  visible: boolean
  descriptionUrl: string | null
}>()
const emits = defineEmits(['update:visible'])
const isVisible = ref(props.visible)

const documentHtml = ref('')
watchEffect(async () => {
  if (props.descriptionUrl) {
    console.debug(props.descriptionUrl)
    const { status, data } = await clinet.get(props.descriptionUrl)
    if (status === 200) {
      documentHtml.value = data
    }
  }
})
watch(isVisible, (val) => {
  emits('update:visible', val)
})
</script>
<style lang="less" scoped></style>
