<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
})
</script>
<template>
  <a-config-provider
    :locale="zh_CN"
    :theme="{
      token: {
        colorPrimary: systemConfig.state.color,
        borderRadius: 2
      }
    }"
  >
    <!-- algorithm: theme.darkAlgorithm,夜间主题 -->
    <router-view />
  </a-config-provider>
</template>

<script lang="ts" setup name="App">
import zh_CN from 'ant-design-vue/es/locale/zh_CN'
import emitter from '@/utils/eventBus'
import storage from './utils/Storage'
import { ACCESS_TOKEN } from './store/mutation-types'
import { onErrorCaptured, h, onMounted } from 'vue'
import { setDeviceType } from '@/utils/device'
import { useRouter } from 'vue-router'
import { Modal } from 'ant-design-vue'
import { systemConfig } from '@/store/reactiveState'
import { getUserInfo, signOut } from '@/services/auth'

window.onresize = setDeviceType
setDeviceType()

const router = useRouter()
emitter.once('axios_goto_login', () => {
  router.push({ name: 'login' })
})

//全局错误处理
onErrorCaptured((err, instance, info) => {
  console.error(err, instance, info)
  // if (window.env !== 'localhost') {
  //   // debugger
  //   Modal.error({
  //     title: 'System Error',
  //     content: h('pre', err.stack),
  //     class: 'errorCapturedModal',
  //     width: '60%'
  //   })
  // }
})

onMounted(async () => {
  if (storage.get(ACCESS_TOKEN)) {
    const { isSuccess, value } = await getUserInfo()
    if (!isSuccess) {
      signOut()
    }
  }
})
</script>

<style></style>
