import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult } from '@/services/types'
import { ChannelAccount, ChannelAccountPagedQueryInput, DepositRecord, DepositRecordPagedQueryInput, DepositRecordWithPaymentData } from './types'

export const Status = {
  Available: {
    value: 'Available',
    label: '匹配中'
  },
  Unavailable: {
    value: 'Unavailable',
    label: '不可用'
  },
  Progressing: {
    value: 'Progressing',
    label: '进行中'
  }
}

export const getStatusTagColor = (status: string) => {
  switch (status) {
    case Status.Available.value:
      return 'processing'
    case Status.Unavailable.value:
      return 'error'
    case Status.Progressing.value:
      return 'warning'
  }
}

export const getList = async (input: ChannelAccountPagedQueryInput): Promise<Result<PagedResult<ChannelAccount>>> => {
  const url = baseURL + '/api/admin/channel-accounts'
  const { status, data } = await clinet.get(url, { params: input })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const get = async (id: string): Promise<Result<ChannelAccount>> => {
  const url = baseURL + `/api/admin/channel-accounts/${id}`
  const { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getDepositRecord = async (id: string): Promise<Result<DepositRecordWithPaymentData>> => {
  const url = baseURL + `/api/admin/channel-accounts/deposit-records/${id}`
  const { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getDepositRecordList = async (input: DepositRecordPagedQueryInput): Promise<Result<PagedResult<DepositRecord>>> => {
  const url = baseURL + '/api/admin/channel-accounts/deposit-records'
  const { status, data } = await clinet.get(url, { params: input })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const remove = async (id: string): Promise<Result> => {
  const url = baseURL + `/api/admin/channel-accounts/${id}`
  const { status } = await clinet.delete(url)
  if (status != 204) return Results.fail()
  return Results.ok()
}

export const reset = async (id: string): Promise<Result<ChannelAccount>> => {
  const url = baseURL + `/api/admin/channel-accounts/${id}/reset`
  const { status, data } = await clinet.put(url)
  if (status != 200) return Results.fail(data.error.message)
  return Results.ok(data)
}

export const toggle = async (id: string): Promise<Result<ChannelAccount>> => {
  const url = baseURL + `/api/admin/channel-accounts/${id}/toggle`
  const { status, data } = await clinet.put(url)
  if (status != 200) return Results.fail(data.error.message)
  return Results.ok(data)
}
