import clinet from '@/utils/http/axios'
import { Result, Results } from '@/utils/results'
import { baseURL } from '@/utils/util'
import { FileUploadInput, FileUploadOutput } from './types'

export const upload = async (input: FileUploadInput): Promise<Result<FileUploadOutput>> => {
  const url = `${baseURL}/files/upload`
  const formData = new FormData()
  formData.append('file', input.file)
  formData.append('fileName', input.fileName)
  const { status, data } = await clinet.postForm(url, formData)
  if (status != 200) return Results.fail('文件上传失败！')
  return Results.ok(data)
}
