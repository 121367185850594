<template>
  <a-row>
    <a-col :span="15">
      <UserSelect v-model:value="userId" :search-params="{ hasTenantId: false }" />
    </a-col>
    <a-col :span="9">
      <a-button type="link" @click="onCreateUser">新建用户</a-button>
    </a-col>
  </a-row>
  <UserCreateModal v-model:visible="isVisibleCreate" @submitted="onUserCreated" />
</template>
<script lang="ts" setup>
import { ref, watch, watchEffect } from 'vue'
import UserSelect from '@/components/Standards/UserSelect.vue'
import UserCreateModal from './UserCreateModal.vue'
import { User } from '@/services/admin/user/types'

const props = defineProps<{
  value: string
}>()
const emits = defineEmits(['update:value'])
const userId = ref<string>(props.value)
const isVisibleCreate = ref<boolean>(false)
const onCreateUser = () => {
  isVisibleCreate.value = true
}
const onUserCreated = (user: User) => {
  userId.value = user.id
  isVisibleCreate.value = false
}
watch(userId, (val) => {
  emits('update:value', val)
})
watchEffect(() => (userId.value = props.value))
</script>
<style lang="less" scoped></style>
