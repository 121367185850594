export type Result<T = any> = {
  isSuccess: boolean
  value?: T
  error?: string
}

export class Results {
  static ok<T>(value?: T): Result<T> {
    return {
      isSuccess: true,
      value
    }
  }

  static fail(error?: string): Result {
    return {
      isSuccess: false,
      error
    }
  }
}
