<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChannelAccountEdit',
})
</script>
<template>
  <a-alert v-if="account.unavailableReason" :message="account.unavailableReason" type="error" banner style="margin-bottom: 1rem" />
  <a-row>
    <a-col :md="12" :sm="24">
      <a-form labelAlign="right" :labelCol="{ span: 6 }">
        <a-form-item label="当前状态">
          <a-tag :color="service.getStatusTagColor(account.status)">
            {{ service.Status[account.status].label }}
          </a-tag>
          <a-popconfirm v-if="canReset" title="确定要重置此账号吗？" @confirm="onReset">
            <a-button type="link" :loading="isResting">
              <span>重置</span>
              <a-popover>
                <template #content>
                  <span>将账号重置为可用状态</span>
                </template>
                <QuestionCircleOutlined />
              </a-popover>
            </a-button>
          </a-popconfirm>
        </a-form-item>
        <a-form-item label="所属商家">
          <span>{{ account.tenant.name }}</span>
        </a-form-item>
        <a-form-item label="所属通道">
          <span>{{ account.channel.name }}</span>
        </a-form-item>
        <a-form-item label="支付方式">
          <a-tag :color="account.payMethod ? 'blue' : 'warning'">{{ account.payMethod?.name ?? '未指定' }}</a-tag>
        </a-form-item>
        <a-form-item label="唯一标识">
          <span>{{ account.id }}</span>
        </a-form-item>
        <a-form-item label="账号名称">
          <span>{{ account.name }}</span>
        </a-form-item>
        <a-form-item label="游戏账号">
          <span>{{ account.userName }}</span>
        </a-form-item>
        <a-form-item label="游戏密码">
          <a-space>
            <span>{{ password }}</span>
            <a-button type="link" @click="isHidePassword = !isHidePassword">
              <EyeOutlined v-if="isHidePassword" />
              <EyeInvisibleOutlined v-else />
            </a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-col>
    <a-col :md="12" :sm="24">
      <a-form labelAlign="right" :labelCol="{ span: 6 }">
        <a-form-item label="是否启用">
          <BooleanLabel :value="account.isEnabled" />
          <a-button type="link" :loading="isToggle" @click="onToggle">
            {{ account.isEnabled ? '停用' : '启用' }}
          </a-button>
        </a-form-item>
        <a-form-item label="充值额度">
          <span> {{ account.payableAmount }}</span>
        </a-form-item>
        <a-form-item label="实付金额">
          <span> {{ account.actualAmount }}</span>
        </a-form-item>
        <a-form-item label="创建时间">
          <DateTimeLabel :value="account.createdOn" />
        </a-form-item>
        <a-form-item label="修改时间">
          <DateTimeLabel :value="account.modifiedOn" />
        </a-form-item>
      </a-form>
    </a-col>
  </a-row>
</template>
<script lang="ts" name="ChannelAccountEdit" setup>
import { ChannelAccountWithRecords } from '@/services/admin/channelAccount/types'
import { useRouter } from 'vue-router'
import { EyeOutlined, EyeInvisibleOutlined, QuestionCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
import { ref, onMounted, computed, watchEffect, watch } from 'vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import * as service from '@/services/admin/channelAccount'
const props = defineProps<{
  value: ChannelAccountWithRecords
}>()
const emits = defineEmits(['update:value'])
const router = useRouter()
const account = ref<ChannelAccountWithRecords>(props.value)
const isHidePassword = ref(true)
const password = computed(() => {
  return isHidePassword.value ? '******' : account.value.password
})

const canReset = computed(() => {
  return account.value.status != service.Status.Available.value
})

const isResting = ref(false)
const onReset = async () => {
  isResting.value = true
  const { value } = await service.reset(account.value.id)
  isResting.value = false
  if (value) {
    account.value = value
  }
}
const isToggle = ref(false)
const onToggle = async () => {
  isToggle.value = true
  const { value } = await service.toggle(account.value.id)
  isToggle.value = false
  if (value) {
    account.value = value
  }
}

watch(account, (val) => {
  emits('update:value', val)
})
watchEffect(() => {
  account.value = props.value
})
</script>
<style lang="less" scoped></style>
