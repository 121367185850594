<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Editor',
})
</script>
<template>
  <div style="border: 1px solid #ccc">
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor :style="props.style" v-model="html" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" @onChange="onChanged" />
  </div>
</template>
<script lang="ts" name="Editor" setup>
import '@wangeditor/editor/dist/css/style.css'
import { ref, reactive, shallowRef, onBeforeUnmount, watchEffect } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { IEditorConfig } from '@wangeditor/editor'
import { upload } from '@/services/upload'
import clinet from '@/utils/http/axios'

const props = defineProps({
  value: {
    type: String,
    default: ''
  },
  url: {
    type: String,
    default: ''
  },
  style: {
    type: Object,
    default: {
      height: '300px',
      overflowY: 'hidden'
    }
  }
})
const emits = defineEmits(['update:value'])

const mode = ref('default')
const editorRef = shallowRef()
const html = ref('')
const originalHtml = ref('')

const toolbarConfig = {
  excludeKeys: ['group-video']
}
const editorConfig: Partial<IEditorConfig> = {
  placeholder: '请输入内容...',
  MENU_CONF: {
    uploadImage: {
      customUpload: async (file: File, insert) => {
        const extension = file.name.split('.').pop()
        const fileName = `editor/images/${Date.now()}.${extension}`
        const { isSuccess, value } = await upload({
          file,
          fileName
        })
        if (isSuccess) {
          insert(value.url, fileName)
        }
      }
    }
  }
}

const onCreated = (editor) => {
  editorRef.value = editor
}
const onChanged = (editor) => {
  emits('update:value', editor.getHtml())
}

watchEffect(async () => {
  if (props.url) {
    const { status, data } = await clinet.get(props.url)
    if (status === 200) {
      html.value = data
      originalHtml.value = data
    }
  } else {
    html.value = ''
    originalHtml.value = ''
  }
})

onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

defineExpose({
  hasChanged() {
    return html.value !== originalHtml.value
  }
})
</script>
<style lang="less" scoped></style>
