import storage from '@/utils/Storage'
import { Router } from 'vue-router'
import { USER_INFO } from '@/store/mutation-types'
import { LoginUser } from '@/services/auth/types'
import commonRoutes from '@/router/commonRoutes'
import { admin } from '@/router/adminRouterMap'
import { tenant } from '@/router/tenantRouterMap'
import { verifier } from '@/router/verifierRouterMap'

export const initRoute = (router: Router) => {
  const user = storage.get(USER_INFO) as LoginUser
  if (!user?.roles || user.roles.length <= 0) return

  if (user.roles.find((x) => x.id === 'tenant')) {
    router.addRoute(tenant)
  } else if (user.roles.find((x) => x.id === 'verifier')) {
    router.addRoute(verifier)
  } else {
    router.addRoute(admin)
  }
  console.debug('route init', router.getRoutes())
}

export const resetRoute = (router) => {
  router.clearRoutes()
  commonRoutes.forEach((r) => {
    router.addRoute(r)
  })
  console.debug('route reset', router.getRoutes())
}
