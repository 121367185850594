<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantCreate',
})
</script>
<template>
  <a-flex>
    <a-card style="width: 100%" title="新建商家">
      <a-form :model="formModel" :labelCol="{ span: 6 }">
        <a-form-item name="userId" :rules="formRules.userId" v-bind="form.validateInfos.userId" label="用户名称">
          <a-row>
            <a-col :span="15">
              <UserSelect v-model:value="formModel.userId" />
            </a-col>
            <a-col :span="9">
              <a-button type="link" @click="onCreateUser">新建用户</a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item name="balance" v-bind="form.validateInfos.balance" label="可用额度">
          <a-row>
            <a-col :span="15">
              <a-input-number :min="0" :step="100" v-model:value="formModel.balance" style="width: 100%" />
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item name="commissionRate" v-bind="form.validateInfos.commissionRate" label="佣金比例">
          <a-row>
            <a-col :span="15">
              <a-input-number
                :min="0"
                :step="0.001"
                v-model:value="formModel.commissionRate"
                style="width: 100%"
                :formatter="(value) => `${(value * 100).toFixed(2)}%`"
                :parser="(value) => (parseFloat(value.replace('%', '')) / 100).toFixed(4)"
              />
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item name="channels" :rules="formRules.channels" v-bind="form.validateInfos.channels" label="充值通道">
          <a-transfer
            v-model:target-keys="formModel.channels"
            :data-source="channels"
            :rowKey="(item) => item.id"
            :render="(item) => item.name"
            show-select-all
            show-search
            pagination
            style="height: 25rem; width: 100%"
          >
          </a-transfer>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 6, span: 18 }">
          <a-space>
            <a-button type="primary" :loading="isSubmitting" @click="onSubmit"> 提交 </a-button>
            <a-button @click="onReset"> 重置 </a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-card>
  </a-flex>
  <UserCreateModal v-model:visible="isVisiableCreateUser" @submitted="onUserCreated" />
</template>
<script lang="ts" name="TenantCreate" setup>
import { ref, reactive, onMounted, computed } from 'vue'
import router from '@/router'
import { notification } from 'ant-design-vue'
import { useForm } from 'ant-design-vue/es/form'
import { PagedResult } from '@/services/types'
import { TenantCreateInput } from '@/services/admin/tenant/types'
import { Channel, ChannelPagedQueryInput } from '@/services/admin/channel/types'
import UserSelect from '@/components/Standards/UserSelect.vue'
import * as channelService from '@/services/admin/channel'
import * as service from '@/services/admin/tenant'
import UserCreateModal from '../UserCreateModal.vue'

const formModel = ref<TenantCreateInput>({
  userId: '',
  commissionRate: 0,
  balance: 0,
  channels: []
})
const formRules = reactive({
  userId: [{ required: true, message: '请选择一个用户', trigger: 'blur' }],
  channels: [{ type: 'array', required: true, message: '请至少选择一个充值通道', trigger: 'blur' }]
})
const form = useForm(formModel, formRules)
const channelSearchParams = reactive<ChannelPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 100
})
const channelPagedList = ref<PagedResult<Channel> | null>(null)
const channels = computed(() => channelPagedList.value?.items || [])
const commissionRateFormatter = (value: number) => {
  return `${(value * 100).toFixed(2)}%`
}

const isVisiableCreateUser = ref(false)
const onCreateUser = () => {
  isVisiableCreateUser.value = true
}
const onUserCreated = (user) => {
  formModel.value.userId = user.id
}

const isSubmitting = ref(false)
const onSubmit = async () => {
  try {
    await form.validate()
  } catch (e) {
    console.error(e, formModel.value)
    return
  }

  isSubmitting.value = true
  const { isSuccess } = await service.create(formModel.value)
  if (isSuccess) {
    notification.success({ message: '创建成功' })
    router.push({ name: 'TenantList' })
    return
  }
}
const onReset = () => {
  form.resetFields()
}

onMounted(async () => {
  const { value } = await channelService.getList(channelSearchParams)
  channelPagedList.value = value
})
</script>
<style lang="less" scoped></style>
