<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'RouteView',
})
</script>
<template>
  <router-view v-slot="{ Component }" v-if="isKeep">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <router-view v-else />
</template>
<script lang="ts" setup name="RouteView">
import { ref, watch } from 'vue'
import { systemConfig } from '@/store/reactiveState'
import { useRouter } from 'vue-router'

const props = defineProps({
  keepAlive: {
    type: Boolean,
    default: false
  }
})
const router = useRouter()
const isKeep = ref(false)

watch(
  () => router.currentRoute.value,
  (newVal) => {
    const routeKeepAlive = router.currentRoute.value.meta.keepAlive
    return routeKeepAlive || systemConfig.state.multiTab || props.keepAlive
  },
  {
    immediate: true
  }
)
</script>
