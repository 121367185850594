<template>
  <a-upload :accept="accept" :maxCount="1" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :beforeUpload="beforeUpload" :customRequest="uploadFile">
    <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 100%; height: 100%" />
    <div v-else>
      <LoadingOutlined v-if="loading"></LoadingOutlined>
      <PlusOutlined v-else></PlusOutlined>
      <div class="ant-upload-text">Upload</div>
    </div>
  </a-upload>
</template>
<script lang="ts" setup>
import { ref, reactive, watchEffect } from 'vue'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'
import * as service from '@/services/upload'
const props = defineProps({
  value: {
    type: String,
    required: true
  },
  url: {
    type: String,
    required: false,
    default: ''
  },
  folder: {
    type: String,
    required: true
  },
  fileName: {
    type: String,
    required: false,
    default: ''
  }
})
const emits = defineEmits(['update:value'])
const imageUrl = ref(props.url)
const loading = ref(false)
const accept = ref('image/png,image/jpeg,image/gif')
const beforeUpload = (file) => {}
const uploadFile = async (data) => {
  let fileName = data.file.name
  const extension = fileName.split('.').pop()
  console.debug(fileName, extension)
  if (props.fileName) {
    fileName = `${props.fileName}.${extension}`
  } else {
    fileName = `${Date.now()}.${extension}`
  }
  loading.value = true
  const { isSuccess, value } = await service.upload({
    file: data.file,
    fileName: `${props.folder.trim()}/${fileName}`
  })
  loading.value = false
  if (isSuccess) {
    imageUrl.value = value.url
    emits('update:value', value.fileName)
  }
}
watchEffect(() => {
  if (!props.value) {
    imageUrl.value = undefined
  }
  if (props.url) {
    imageUrl.value = props.url
  }
})
</script>
<style lang="less" scoped>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
