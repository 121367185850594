<template>
  <a-modal :open="open" title="新建用户" okText="创建" @cancel="onCancel" @ok="onSubmit" :confirmLoading="isSubmitting">
    <UserCreateForm ref="form" v-model:value="formModel" />
  </a-modal>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue'
import UserCreateForm from '@/views/admin/authModule/users/UserCreateForm.vue'
import { UserCreateFormInstance } from '@/views/admin/authModule/users/types'
import * as service from '@/services/admin/user'

const props = defineProps<{
  visible: boolean
}>()
const emits = defineEmits(['update:visible', 'submitted'])
const open = ref(props.visible)
const form = ref<UserCreateFormInstance | null>(null)
const formModel = ref({
  userName: '',
  password: ''
})

const onCancel = () => {
  open.value = false
}
const isSubmitting = ref(false)
const onSubmit = async () => {
  if (!form.value.validate()) return

  isSubmitting.value = true
  const { isSuccess, value } = await service.create(formModel.value)
  isSubmitting.value = false
  if (isSuccess) {
    form.value.resetFields()
    emits('submitted', value)
    onCancel()
  }
}
watch(
  () => props.visible,
  (val) => {
    open.value = val
  }
)
watch(
  () => open.value,
  (val) => {
    emits('update:visible', val)
  }
)
</script>
<style lang="less" scoped></style>
