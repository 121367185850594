<template>
  <div>
    <a-checkbox :checked="groupChecked" :disabled="groupDisabled" :indeterminate="groupIndeterminate" @change="onGroupChanged"> {{ definition.displayName }} </a-checkbox>
    <a-checkbox-group v-model:value="checked" style="align-self: start; width: 100%">
      <a-row>
        <a-col :span="6">
          <a-checkbox :value="definition.name" :disabled="checkDisabled(definition.name)"> 查看 </a-checkbox>
        </a-col>
        <a-col v-for="item in definition.children" :span="6">
          <a-checkbox :value="item.name" :disabled="checkDisabled(item.name)"> {{ item.displayName }} </a-checkbox>
        </a-col>
      </a-row>
    </a-checkbox-group>
  </div>
</template>
<script lang="ts" setup>
import { PermissionDefinition } from '@/services/admin/permissions/types'
import { ref, computed, watchEffect, watch } from 'vue'
const props = defineProps<{
  definition: PermissionDefinition
  disabledList: string[]
  checkedList: string[]
}>()
const emits = defineEmits(['change'])
const groupPermissions = computed(() => {
  const permissions = []
  permissions.push(props.definition.name)
  if (props.definition.children) {
    props.definition.children.forEach((item) => {
      permissions.push(item.name)
    })
  }
  return permissions
})
const map = (arr: string[]): string[] => {
  return arr.filter((item) => groupPermissions.value.includes(item))
}
const checked = ref(map(props.checkedList))
const groupChecked = computed(() => {
  if (groupIndeterminate.value) return false
  return checked.value.length > 0
})
const groupDisabled = computed(() => {
  const filter = props.disabledList.filter((item) => groupPermissions.value.includes(item))
  return filter.length > 0
})
const groupIndeterminate = computed(() => {
  return checked.value.length > 0 && checked.value.length < groupPermissions.value.length
})
const checkDisabled = (item: string) => {
  return props.disabledList.includes(item) ? true : false
}
const onGroupChanged = (e: any) => {
  if (e.target.checked) {
    checked.value = groupPermissions.value
  } else {
    checked.value = []
  }
}
watchEffect(() => {
  checked.value = map(props.checkedList)
})
watch(checked, () => {
  emits('change', checked.value, groupPermissions.value)
})
</script>
<style lang="less" scoped></style>
