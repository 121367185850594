import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { ListResult } from '@/services/types'
import { VerifierGroup } from './types'

export const getListByTenantId = async (tenantId: string): Promise<Result<ListResult<VerifierGroup>>> => {
  const url = baseURL + '/api/admin/verifier-groups/' + tenantId
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
