<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantChannelQrCode',
})
</script>
<template>
  <a-result title="支付二维码">
    <template #icon>
      <a-flex justify="center">
        <a-qrcode ref="qrcodeRef" size="200" :value="paymentUrl" :status="status" />
      </a-flex>
    </template>
    <template #subTitle>
      <a-row class="description">
        <a-col class="label" :span="6">
          <label>支付链接：</label>
        </a-col>
        <a-col class="value" :span="16">
          <a-flex :gap="8">
            <a-popover :content="paymentUrl">
              <div class="text">
                {{ paymentUrl }}
              </div>
            </a-popover>
            <a @click="copy"><CopyOutlined /></a>
          </a-flex>
        </a-col>
      </a-row>
      <a-row class="description">
        <a-col class="label" :span="6">
          <label>支付通道：</label>
        </a-col>
        <a-col class="value" :span="16">
          <a-space>
            <div class="text">
              {{ channel.name }} - {{ channel.payMethods.filter(x=>x.isEnabled).map(x=>x.name).join(',') }}
            </div>
          </a-space>
        </a-col>
      </a-row>
      <div>
        <a-row class="description">
          <a-col class="label" :span="6">
            <label>随机标识：</label>
          </a-col>
          <a-col class="value" :span="16">
            <div v-if="isEditing">
              <a-input v-model:value="formModel.nonce" placeholder="请输入随机标识" :maxlength="32" style="width: calc(100% - 200px)" />
              <a-button type="link" @click="generateNonce"><SwapOutlined /></a-button>
              <a-button type="link" :loading="isSubmitting" @click="onSubmit">保存</a-button>
              <a-button type="link" @click="isEditing = false">取消</a-button>
            </div>
            <a-space v-else>
              <span class="text">{{ nonce ?? '-' }}</span>
              <a v-if="edit" @click="onEdit"><EditOutlined /></a>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </template>
    <template #extra>
      <a-button type="primary" :disabled="status != 'active'" @click="dowload">下载二维码</a-button>
    </template>
  </a-result>
</template>
<script lang="ts" name="TenantChannelQrCode" setup>
import { ref, reactive, watchEffect, computed, onMounted } from 'vue'
import { notification } from 'ant-design-vue'
import { getCurrentDomain, copyToClipboard } from '@/utils/util'
import { CopyOutlined, EditOutlined, SwapOutlined } from '@ant-design/icons-vue'
import { Channel , ChannelUpdateInput } from '@/services/tenant/channel/types'
import { ChannelWithPayMethods } from '@/services/admin/channel/types'
import * as service from '@/services/tenant/channel'
const props = defineProps<{
  channel: Channel | ChannelWithPayMethods
  tenantId: string
  nonce: string | null
  paymentDomain: string | null
  edit?: boolean
}>()
const nonce = ref(props.nonce)
const qrcodeRef = ref()
const formModel = reactive<ChannelUpdateInput>({ nonce: props.nonce })
const paymentUrl = computed(() => {
  if (!props.channel.isEnabled) return ''
  const domain = props.paymentDomain ?? getCurrentDomain()
  let url = `${domain}/pay/order/${props.tenantId}/${props.channel.id}`
  if (nonce.value) {
    url += `?nonce=${nonce.value}`
  }
  return url
})
const status = computed(() => {
  if (!props.channel.isEnabled) return 'loading'
  if (isSubmitting.value) return 'loading'
  return 'active'
})
const isEditing = ref(false)
const onEdit = (e) => {
  e.preventDefault()
  isEditing.value = true
}
const isSubmitting = ref(false)
const onSubmit = async () => {
  isSubmitting.value = true
  const { value } = await service.update(props.channel.id, formModel)
  isSubmitting.value = false
  if (value) {
    nonce.value = value.nonce
    isEditing.value = false
  }
}
const generateNonce = () => {
  formModel.nonce = Math.random().toString(36).substring(2, 19)
}
const copy = (e) => {
  e.preventDefault()
  if (status.value != 'active') {
    notification.error({ message: '复制失败' })
    return
  }
  try {
    copyToClipboard(paymentUrl.value)
    //navigator.clipboard.writeText(paymentUrl.value)
    notification.success({ message: '复制成功' })
  } catch (e) {
    notification.error({ message: '复制失败' })
    console.error(e)
  }
}
const dowload = async () => {
  console.debug(qrcodeRef.value)
  const url = await qrcodeRef.value.toDataURL()
  const a = document.createElement('a')
  a.download = 'QRCode.png'
  a.href = url
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

watchEffect(() => {
  nonce.value = props.nonce
  formModel.nonce = props.nonce
})
</script>
<style lang="less" scoped>
.description {
  align-items: center;
  margin: 1rem 0;
  line-height: 2rem;
  .label {
    text-align: right;
  }
  .value {
    text-align: left;
    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
