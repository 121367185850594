<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BooleanLabel',
})
</script>
<template>
  <CheckCircleTwoTone v-if="value" two-tone-color="#52c41a" />
  <CloseCircleTwoTone v-else two-tone-color="#ff4d4f" />
</template>
<script lang="ts" setup name="BooleanLabel">
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons-vue'
defineProps({
  value: {
    type: Boolean,
    default: false
  }
})
</script>
<style lang="less" scoped></style>
