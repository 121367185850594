<template>
  <a-row :gutter="[16, 16]">
    <a-col :md="6" :sm="24">
      <a-card title="核销信息">
        <p>
          <span>当前状态：</span>
          <a-tag :color="value.isEnabled ? 'success' : 'error'">{{ value.isEnabled ? '正常' : '停用' }}</a-tag>
        </p>
        <p>
          <span>核销名称：</span>
          <span>{{ value.user.userName }}</span>
        </p>
        <p>
          <span>日订单数：</span>
          <span>{{ value.dailyOrderCount }}</span>
        </p>
        <p>
          <span>日充值额：</span>
          <span>￥{{ value.dailyDeposit }}</span>
        </p>
        <p>
          <span>分组名称：</span>
          <span>{{ value.group.name }}</span>
        </p>

        <p v-if="value.isExternal">
          <span>每日限额：</span>
          <span>￥{{ value.external.dailyLimit }}</span>
        </p>
        <p v-if="value.isExternal">
          <span>佣金比例：</span>
          <span>{{ `${(value.external.commissionRate * 100).toFixed(2)}%` }}</span>
        </p>
        <p v-if="value.isExternal">
          <span>累计佣金：</span>
          <span>￥{{ value.external.totalCommission }}</span>
        </p>

        <p>
          <span>创建时间：</span>
          <DateTimeLabel :value="value.createdOn" />
        </p>
      </a-card>
    </a-col>
    <a-col :md="18" :sm="24">
      <a-card title="最新账号">
        <a-table :columns="columns" :data-source="accounts" :loading="loading" :pagination="false">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'channelId'">
              <span>{{ record.channel.name }}</span>
            </template>
            <template v-else-if="column.key === 'userName'">
              <a-popover>
                <template #content>
                  <p>游戏账号: {{ record.userName }}</p>
                  <p>游戏昵称: {{ record.name }}</p>
                </template>
                <span>{{ record.userName }}</span>
              </a-popover>
            </template>
            <template v-else-if="column.key === 'payableAmount'">
              <span>{{ `￥${record.payableAmount}` }}</span>
            </template>
            <template v-else-if="column.key === 'totalAmount'">
              <span>{{ `￥${record.actualAmount} / ${record.totalAmount}` }}</span>
            </template>
            <template v-else-if="column.key === 'status'">
              <a-tag :bordered="false" :color="getStatusTagColor(record.status)">
                <template #icon>
                  <CloseCircleOutlined v-if="record.status === Status.Unavailable.value" />
                  <SyncOutlined :spin="true" v-else />
                </template>
                {{ Status[record.status].label }}
              </a-tag>
            </template>
            <template v-else-if="column.key === 'isEnabled'">
              <BooleanLabel :value="record.isEnabled" />
            </template>
            <template v-else-if="column.key === 'createdOn'">
              <DateTimeLabel :value="record.createdOn" />
            </template>
          </template>
        </a-table>
      </a-card>
    </a-col>
  </a-row>
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted } from 'vue'
import { Verifier } from '@/services/verifier/types'
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons-vue'
import { Status, getStatusTagColor } from '@/services/admin/channelAccount'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import { ChannelAccount } from '@/services/tenant/accounts/types'
import * as accountService from '@/services/verifier/account'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
defineProps<{
  value: Verifier
}>()
const accounts = ref<ChannelAccount[]>([])
const columns = reactive([
  {
    title: '所属通道',
    key: 'channelId',
    dataIndex: 'channelId'
  },
  {
    title: '游戏账号',
    key: 'userName',
    dataIndex: 'userName'
  },
    {
    title: '游戏昵称',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: '可用额度',
    key: 'payableAmount',
    dataIndex: 'payableAmount'
  },
  {
    title: '已充金额',
    key: 'totalAmount',
    dataIndex: 'totalAmount'
  },
  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    align: 'center'
  },
  {
    title: '启用',
    key: 'isEnabled',
    dataIndex: 'isEnabled',
    align: 'center',
    width: '10%'
  }
])

const loading = ref(false)
const fetchAccounts = async () => {
  loading.value = true
  const { value } = await accountService.getList({})
  loading.value = false
  if (value) {
    accounts.value = value.items
  }
}
onMounted(() => {
  fetchAccounts()
})
</script>
<style lang="less" scoped></style>
