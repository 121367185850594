import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { PagedResult, ListResult } from '@/services/types'
import { Order, OrderPagedQueryInput } from './types'

export const get = async (id: string): Promise<Result<Order>> => {
  const url = baseURL + `/api/tenant/orders/${id}`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (input: OrderPagedQueryInput): Promise<Result<PagedResult<Order>>> => {
  const url = baseURL + '/api/tenant/orders'
  var { status, data } = await clinet.get(url, { params: input })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const abort = async (id: string): Promise<Result<Order>> => {
  const url = baseURL + `/api/tenant/orders/${id}/abort`
  var { status, data } = await clinet.put(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const remove = async (id: string): Promise<Result> => {
  const url = baseURL + `/api/tenant/orders/${id}`
  var { status } = await clinet.delete(url)
  if (status != 204) return Results.fail()
  return Results.ok()
}
