import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { ACCESS_TOKEN } from '@/store/mutation-types'
import sotrage from '@/utils/Storage'
import { setDocumentTitle } from '@/utils/domUtil'
import type { Router } from 'vue-router'
import { notification } from 'ant-design-vue'

// NProgress Configuration
NProgress.configure({ showSpinner: false })

// 不进行拦截的路由名称集合
const whiteList = ['login', 'pay-order', '403', '404', '500']
export const setupBeforeEach = (router: Router) => {
  router.beforeEach((to, from, next) => {
    // 加载进度条
    NProgress.start()
    setDocumentTitle(to)
    // 在免登录白名单，直接进入
    if (whiteList.includes(to.name as any)) {
      next()
    } else if (sotrage.get(ACCESS_TOKEN)) {
      //const permission = ls.get(PERMISSION)
      //const canAccess = hasPermission(permission, to)
      //next({ path: '/exception/403' })
      next()
    } else {
      next({ path: '/user/login', query: { redirect: to.fullPath } })
    }
    NProgress.done()
  })

  // 路由懒加载失败时的提示
  router.onError((error) => {
    if (window.env === 'localhost') {
      notification.error({
        message: 'Dynamic import error',
        description: error.stack
      })
    } else {
      router.push({ name: 'error', params: { errorMsg: error.stack } })
    }
  })
}

export const setupAfterEach = (router: Router) => {
  router.afterEach(() => {
    NProgress.done() // finish progress bar
  })
}
