import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { ListResult, PagedResult } from '@/services/types'
import { Tenant, TenantChannel, TenantCreateInput, TenantPagedQueryInput, TenantUpdateInput, TenantWithChannels } from './types'

export const get = async (id: string): Promise<Result<TenantWithChannels>> => {
  const url = baseURL + `/api/admin/tenants/${id}`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getList = async (query: TenantPagedQueryInput): Promise<Result<PagedResult<Tenant>>> => {
  const url = baseURL + '/api/admin/tenants'
  var { status, data } = await clinet.get(url, { params: query })
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const getChannelList = async (id: string): Promise<Result<ListResult<TenantChannel>>> => {
  const url = baseURL + `/api/admin/tenants/${id}/channels`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const create = async (input: TenantCreateInput): Promise<Result<TenantWithChannels>> => {
  const url = baseURL + '/api/admin/tenants'
  var { status, data } = await clinet.post(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const update = async (id: string, input: TenantUpdateInput): Promise<Result<TenantWithChannels>> => {
  const url = baseURL + `/api/admin/tenants/${id}`
  var { status, data } = await clinet.put(url, input)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}

export const remove = async (id: string): Promise<Result> => {
  const url = baseURL + `/api/admin/tenants/${id}`
  var { status } = await clinet.delete(url)
  if (status != 204) return Results.fail()
  return Results.ok()
}
