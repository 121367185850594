<template>
  <DataTable ref="dataTable" :columns="columns" :search-params="searchParams" :search-properties="searchProperties" :fetch="fetchData">
    <template #default="{ column, record }">
      <template v-if="column.dataIndex === 'payableAmount'">
        <span>{{ `￥${record.payableAmount}` }}</span>
      </template>
      <template v-else-if="column.dataIndex === 'actualAmount'">
        <span>{{ `￥${record.actualAmount}` }}</span>
      </template>
      <template v-else-if="column.dataIndex === 'isFinished'">
        <CheckCircleOutlined v-if="record.isFinished" style="color: #389e0d" />
        <SyncOutlined v-else :spin="true" style="color: #1677ff" />
      </template>
      <template v-else-if="column.dataIndex === 'remark'">
        <span v-if="record.failedReason" style="color: #ff4d4f">{{ record.failedReason }}</span>
        <span v-else>{{ record.orderNo }}</span>
      </template>
      <template v-else-if="column.dataIndex === 'orderId'">
        <router-link :to="{ name: 'TenantOrderEdit', params: { id: record.orderId } }">
          {{ record.orderId }}
        </router-link>
      </template>
      <template v-else-if="column.dataIndex === 'createdOn'">
        <DateTimeLabel :value="record.createdOn" format="YY-MM-DD HH:mm:ss" />
      </template>
      <template v-else-if="column.dataIndex === 'modifiedOn'">
        <DateTimeLabel :value="record.modifiedOn" />
      </template>
    </template>
  </DataTable>
  <a-flex justify="flex-end" style="margin-top: 1rem">
    <a-button @click="router.back">返回</a-button>
  </a-flex>
</template>
<script lang="ts" setup>
import { ref, reactive, watchEffect, computed } from 'vue'
import { useRouter } from 'vue-router'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import { ChannelAccount, DepositRecord, DepositRecordPagedQueryInput } from '@/services/tenant/accounts/types'
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons-vue'
import * as service from '@/services/tenant/accounts'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
const props = defineProps<{
  value: ChannelAccount
}>()
const router = useRouter()
const columns = [
  {
    title: '创建时间',
    dataIndex: 'createdOn',
    width: '15%',
    sorter: true
  },
  {
    title: '应付金额',
    dataIndex: 'payableAmount',
    sorter: true
  },
  {
    title: '实付金额',
    dataIndex: 'actualAmount',
    sorter: true
  },
  {
    title: '是否完成',
    dataIndex: 'isFinished',
    align: 'center',
    width: '6rem'
  },
  {
    title: '匹配订单',
    dataIndex: 'orderId',
    width: '20%',
    ellipsis: true
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: '30%',
    ellipsis: true
  }
]
const searchProperties = {
  payMethodId: {
    label: '支付方式',
    type: 'payMethod',
    options: {
      placeholder: '请选择支付方式'
    }
  },
  orderNo: {
    label: '订单号',
    type: 'input',
    options: {
      placeholder: '请输入订单号'
    }
  },
  payableAmount: {
    label: '下单金额',
    type: 'number',
    options: {
      placeholder: '请输入下单金额'
    }
  }
}
const dataTable = ref<DataTableInstance | null>(null)
const account = ref<ChannelAccount>(props.value)
const searchParams = reactive<DepositRecordPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10,
  accountId: account.value.id
})
const fetchData = (params) => {
  params.accountId = account.value.id
  return service.getDepositRecordList(params)
}
watchEffect(() => {
  account.value = props.value
})
</script>
<style lang="less" scoped></style>
