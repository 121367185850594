<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantChannelAccountList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <DataTable ref="dataTable" :columns="columns" :search-properties="searchProperties" :search-params="searchParams" :fetch="fetchData">
      <template #default="{ column, record }">
        <template v-if="column.key === 'channelId'">
          <a-popover>
            <template #content>
              <p>
                支付方式: <a-tag :bordered="false" :color="record.payMethod ? 'blue' : 'warning'">{{ record.payMethod?.name ?? '未指定' }}</a-tag>
              </p>
            </template>
            <span>{{ record.channel.name }}</span>
          </a-popover>
        </template>
        <template v-else-if="column.key === 'ownerId'">
          <span>{{ record.owner.userName }}</span>
        </template>
        <template v-else-if="column.key === 'userName'">
          <a-popover>
            <template #content>
              <p>游戏账号: {{ record.userName }}</p>
              <p>游戏昵称: {{ record.name }}</p>
            </template>
            <span>{{ record.userName }}</span>
          </a-popover>
        </template>
        <template v-else-if="column.key === 'name'">
          <a-popover>
            <template #content>
              <div>账号昵称：{{ record.name }}</div>
              <div>游戏账号：{{ record.userName }}</div>
            </template>
            <span>{{ record.name }} </span>
          </a-popover>
        </template>
        <template v-else-if="column.key === 'totalAmount'">
          <div v-if="isCellEdit(record, column.key)">
            <a-popconfirm
              :open="isCellEdit(record, column.key)"
              title="确定要修改吗?"
              ok-text="修改"
              cancel-text="取消"
              :okButtonProps="{ loading: isCellEditing }"
              @cancel="onCellEditCancel"
              @confirm="onSetPayableAmount"
            >
              <a-input-number v-model:value="editItem.totalAmount" :min="record.actualAmount" />
            </a-popconfirm>
          </div>
          <div v-else>
            <span> {{ `￥${record.actualAmount} / ${record.totalAmount}` }}</span>
            <EditOutlined class="editable-cell-icon" @click="onCellEdit(record, column.key)" />
          </div>
        </template>
        <template v-else-if="column.key === 'payableAmount'">
          <span>{{ `￥${record.payableAmount}` }}</span>
        </template>
        <template v-else-if="column.key === 'status'">
          <ChannelAccountStatus :record="record" />
        </template>
        <template v-else-if="column.key === 'createdOn'">
          <DateTimeLabel format="YY-MM-DD HH:mm" :value="record.createdOn" />
        </template>
        <template v-else-if="column.key === 'isEnabled'">
          <div v-if="isCellEdit(record, column.key)">
            <a-switch v-model:checked="editItem.isEnabled" :loading="isCellEditing" @change="onCellEditSave" />
          </div>
          <div v-else>
            <BooleanLabel :value="record.isEnabled" />
            <EditOutlined class="editable-cell-icon" @click="onCellEdit(record, column.key)" />
          </div>
        </template>
        <template v-else-if="column.key === 'action'">
          <a-space>
            <DetailButton @click="onEdit(record)" />
            <DeleteButton :loading="currentRecord?.id == record.id" @delete="onDelete(record)" />
          </a-space>
        </template>
      </template>
    </DataTable>
  </a-flex>
</template>
<script lang="ts" name="TenantChannelAccountList" setup>
import { ref, reactive, onMounted } from 'vue'
import router from '@/router'
import { notification } from 'ant-design-vue'
import { EditOutlined } from '@ant-design/icons-vue'
import { ChannelAccount, ChannelAccountPagedQueryInput } from '@/services/tenant/accounts/types'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import DetailButton from '@/components/Standards/DetailButton.vue'
import DeleteButton from '@/components/Standards/DeleteButton.vue'
import columns from './columns.json'
import search from './searchProperties.json'
import { Status } from '@/services/admin/channelAccount'
import ChannelAccountStatus from '@/components/Standards/ChannelAccountStatus.vue'
import * as service from '@/services/tenant/accounts'

const currentRecord = ref<ChannelAccount | null>(null)
const dataTable = ref<DataTableInstance | null>(null)
const searchProperties = reactive(search)
const searchParams = reactive<ChannelAccountPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})

const editItem = ref<ChannelAccount>()
const editKey = ref('')
const isCellEdit = (item: ChannelAccount, key: string) => {
  return item.id === editItem.value?.id && key === editKey.value
}
const onCellEdit = (item: ChannelAccount, key: string) => {
  editItem.value = { ...item }
  editKey.value = key
}
const onCellEditCancel = () => {
  editItem.value = null
  editKey.value = ''
}

const isCellEditing = ref(false)
const onCellEditSave = async () => {
  isCellEditing.value = true
  const { value } = await service.update(editItem.value.id, service.map(editItem.value))
  isCellEditing.value = false
  if (value) {
    onCellEditCancel()
    dataTable.value?.refresh()
  }
}

const onSetPayableAmount = async () => {
  isCellEditing.value = true
  const { value } = await service.setPayableAmount(editItem.value.id, {
    payableAmount: editItem.value.totalAmount
  })
  isCellEditing.value = false
  if (value) {
    onCellEditCancel()
    dataTable.value?.refresh()
  }
}

const fetchData = (params) => {
  return service.getList(params)
}
const onDelete = async (record: ChannelAccount) => {
  currentRecord.value = record
  const { isSuccess } = await service.remove(record.id)
  currentRecord.value = null
  if (isSuccess) {
    notification.success({ message: '删除成功' })
    dataTable.value?.refresh()
  }
}
const onEdit = (record: ChannelAccount) => {
  router.push({ name: 'TenantChannelAccountEdit', params: { id: record.id } })
}
onMounted(() => {
  searchProperties.status.options.items = []
  for (const key in Status) {
    if (Object.prototype.hasOwnProperty.call(Status, key)) {
      const item = Status[key]
      searchProperties.status.options.items.push(item)
    }
  }
})
</script>
<style lang="less" scoped>
.editable-cell-icon {
  position: absolute;
  right: 0;
  top: 40%;
  cursor: pointer;
}
.editable-cell-icon:hover {
  color: #108ee9;
}
</style>
