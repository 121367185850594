<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PayMethodList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <DataTable ref="dataTable" :columns="columns" :search-properties="searchProperties" :search-params="searchParams" :fetch="fetchData">
      <template #actions>
        <CreateButton @click="onCreate" />
      </template>
      <template #default="{ column, record }">
        <template v-if="column.key === 'icon'">
          <a-avatar shape="square" size="large" :src="record.iconFullUrl">
            <template #icon><AntDesignOutlined /></template>
          </a-avatar>
        </template>
        <template v-else-if="column.key === 'createdOn'">
          <DateTimeLabel :value="record.createdOn" />
        </template>
        <template v-else-if="column.key === 'modifiedOn'">
          <DateTimeLabel :value="record.modifiedOn" />
        </template>
        <template v-else-if="column.key === 'isEnabled'">
          <BooleanLabel :value="record.isEnabled" />
        </template>
        <template v-else-if="column.key === 'action'">
          <a-space>
            <DetailButton @click="onUpdate(record)" />
            <DeleteButton :loading="isDeleting && currentRecord?.id == record.id" />
          </a-space>
        </template>
      </template>
    </DataTable>
  </a-flex>

  <PayMethodCreateDrawer v-model:visible="isCreateVisible" @submitted="dataTable?.refresh()" />
  <PayMethodUpdateDrawer v-if="currentRecord" v-model:visible="isUpdateVisible" :value="currentRecord" @submitted="dataTable?.refresh()" />
</template>
<script lang="ts" name="PayMethodList" setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { AntDesignOutlined } from '@ant-design/icons-vue'
import { PayMethod, PayMethodPagedQueryInput } from '@/services/admin/payMethod/types'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import CreateButton from '@/components/Standards/CreateButton.vue'
import columns from './columns.json'
import searchProperties from './searchProperties.json'
import * as service from '@/services/admin/payMethod'
import PayMethodCreateDrawer from './PayMethodCreateDrawer.vue'
import PayMethodUpdateDrawer from './PayMethodUpdateDrawer.vue'
import DetailButton from '@/components/Standards/DetailButton.vue'
import DeleteButton from '@/components/Standards/DeleteButton.vue'

const isCreateVisible = ref(false)
const isUpdateVisible = ref(false)
const currentRecord = ref<PayMethod | null>(null)
const dataTable = ref<DataTableInstance | null>(null)
const searchParams = reactive<PayMethodPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})
const fetchData = (params) => {
  return service.getList(params)
}
const onCreate = () => {
  isCreateVisible.value = true
}
const onUpdate = (record: PayMethod) => {
  currentRecord.value = record
  isUpdateVisible.value = true
}
const isDeleting = ref(false)
</script>
<style lang="less" scoped></style>
