<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TenantDetail',
})
</script>
<template>
  <a-form :model="formModel" :labelCol="{ span: 6 }">
    <a-form-item name="userId" label="用户名称">
      <span>{{ tenant.name }}</span>
    </a-form-item>
    <a-form-item name="balance" v-bind="form.validateInfos.balance" label="可用额度">
      <a-row>
        <a-col :span="15">
          <a-input-number :min="0" :step="100" v-model:value="formModel.balance" style="width: 100%" />
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item name="commissionRate" v-bind="form.validateInfos.commissionRate" label="佣金比例">
      <a-row>
        <a-col :span="15">
          <a-input-number
            :min="0"
            :step="0.001"
            v-model:value="formModel.commissionRate"
            style="width: 100%"
            :formatter="(value) => `${(value * 100).toFixed(2)}%`"
            :parser="(value) => (parseFloat(value.replace('%', '')) / 100).toFixed(4)"
          />
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item name="channels" :rules="formRules.channels" v-bind="form.validateInfos.channels" label="充值通道">
      <a-transfer
        v-model:target-keys="formModel.channels"
        :data-source="channels"
        :rowKey="(item) => item.id"
        :render="(item) => item.name"
        show-select-all
        show-search
        pagination
        style="height: 30rem"
      >
      </a-transfer>
    </a-form-item>
    <a-form-item name="isEnabled" label="是否启用">
      <a-switch v-model:checked="formModel.isEnabled" />
    </a-form-item>
    <a-form-item name="recordKeepDays" v-bind="form.validateInfos.recordKeepDays" label="记录保存天数">
      <a-row>
        <a-col :span="15">
          <a-input-number :min="1" v-model:value="formModel.recordKeepDays" style="width: 100%" />
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item :wrapper-col="{ offset: 6, span: 18 }">
      <a-space>
        <a-button type="primary" :loading="isSubmitting" @click="onSubmit"> 提交 </a-button>
        <a-button @click="onReset"> 重置 </a-button>
      </a-space>
    </a-form-item>
  </a-form>
</template>
<script lang="ts" name="TenantDetail" setup>
import { ref, reactive, onMounted, computed, watchEffect } from 'vue'
import router from '@/router'
import { notification } from 'ant-design-vue'
import { useForm } from 'ant-design-vue/es/form'
import { PagedResult } from '@/services/types'
import { TenantWithChannels, TenantUpdateInput } from '@/services/admin/tenant/types'
import { Channel, ChannelPagedQueryInput } from '@/services/admin/channel/types'
import * as channelService from '@/services/admin/channel'
import * as service from '@/services/admin/tenant'

const props = defineProps<{
  value: TenantWithChannels
}>()

const map = (tenant: TenantWithChannels): TenantUpdateInput => {
  return {
    commissionRate: tenant.commissionRate,
    isEnabled: tenant.isEnabled,
    balance: tenant.balance,
    recordKeepDays: tenant.recordKeepDays,
    channels: tenant.channels.map((x) => x)
  }
}
const tenant = ref<TenantWithChannels>(props.value)
const formModel = ref<TenantUpdateInput>(map(props.value))
const formRules = reactive({
  channels: [{ type: 'array', required: true, message: '请至少选择一个充值通道', trigger: 'blur' }]
})
const form = useForm(formModel, formRules)
const channelSearchParams = reactive<ChannelPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 100
})
const channelPagedList = ref<PagedResult<Channel> | null>(null)
const channels = computed(() => channelPagedList.value?.items || [])

const isSubmitting = ref(false)
const onSubmit = async () => {
  try {
    await form.validate()
  } catch (e) {
    console.error(e, formModel.value)
    return
  }

  isSubmitting.value = true
  const { isSuccess } = await service.update(props.value.id, formModel.value)
  isSubmitting.value = false
  if (isSuccess) {
    notification.success({ message: '修改成功' })
    router.push({ name: 'TenantList' })
    return
  }
}
const onReset = () => {
  form.resetFields()
}

watchEffect(() => {
  tenant.value = props.value
  formModel.value = map(props.value)
})

onMounted(async () => {
  const { value } = await channelService.getList(channelSearchParams)
  channelPagedList.value = value
})
</script>
<style lang="less" scoped></style>
