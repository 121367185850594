<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'OrderList',
})
</script>
<template>
  <a-flex gap="middle" :vertical="true">
    <DataTable ref="dataTable" :columns="columns" :search-properties="searchProperties" :search-params="searchParams" :fetch="fetchData">
      <template #default="{ column, record }">
        <template v-if="column.key === 'tenantId'">
          <span>{{ record.tenant?.name }}</span>
        </template>
        <template v-else-if="column.key === 'channelId'">
          <span>{{ record.channel.name }}</span>
        </template>
        <template v-else-if="column.key === 'payMethodId'">
          <span>{{ record.payMethod.name }}</span>
        </template>
        <template v-else-if="column.key === 'status'">
          <a-tag :bordered="false" :color="service.getStatusTagColor(record.status)">
            <template #icon>
              <CheckCircleOutlined v-if="record.status === 'Completed'" />
              <CloseCircleOutlined v-else-if="record.status === 'Aborted'" />
              <SyncOutlined :spin="true" v-else />
            </template>
            {{ service.Status[record.status].label }}
          </a-tag>
        </template>
        <template v-else-if="column.key === 'payableAmount'">
          <span>{{ `￥${record.payableAmount}` }}</span>
        </template>
        <template v-else-if="column.key === 'actualAmount'">
          <span>{{ `￥${record.actualAmount}` }}</span>
        </template>
        <template v-else-if="column.key === 'createdOn'">
          <DateTimeLabel :value="record.createdOn" />
        </template>
        <template v-else-if="column.key === 'action'">
          <a-space>
            <DetailButton @click="onEdit(record)" />
            <DeleteButton :loading="isDeleting && currentRecord?.id == record.id" @delete="onDelete(record)" />
          </a-space>
        </template>
      </template>
    </DataTable>
  </a-flex>
</template>
<script lang="ts" name="OrderList" setup>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import { AntDesignOutlined, CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons-vue'
import { DataTableInstance } from '@/components/Standards/DataTable/types'
import DataTable from '@/components/Standards/DataTable/index.vue'
import DateTimeLabel from '@/components/Standards/DateTimeLabel.vue'
import BooleanLabel from '@/components/Standards/BooleanLabel.vue'
import DetailButton from '@/components/Standards/DetailButton.vue'
import DeleteButton from '@/components/Standards/DeleteButton.vue'
import columns from './columns.json'
import search from './searchProperties.json'
import * as service from '@/services/admin/order'
import { Order, OrderPagedQueryInput } from '@/services/admin/order/types'
const router = useRouter()
const currentRecord = ref<Order | null>(null)
const dataTable = ref<DataTableInstance | null>(null)
const searchProperties = reactive(search)
const searchParams = reactive<OrderPagedQueryInput>({
  skipCount: 0,
  maxResultCount: 10
})

const fetchData = (params) => {
  return service.getList(params)
}
const isDeleting = ref(false)
const onDelete = async (record: Order) => {
  currentRecord.value = record
  isDeleting.value = true
  const { isSuccess } = await service.remove(record.id)
  isDeleting.value = false
  currentRecord.value = null
  if (isSuccess) {
    notification.success({ message: '删除成功' })
    dataTable.value?.refresh()
  }
}
const onEdit = (record: Order) => {
  currentRecord.value = record
  router.push({ name: 'OrderEdit', params: { id: record.id } })
}
onMounted(() => {
  searchProperties.status.options.items = []
  for (const key in service.Status) {
    if (Object.prototype.hasOwnProperty.call(service.Status, key)) {
      const item = service.Status[key]
      searchProperties.status.options.items.push(item)
    }
  }
})
</script>
<style lang="less" scoped></style>
