import clinet from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { Result, Results } from '@/utils/results'
import { Verifier } from './types'

export const get = async (): Promise<Result<Verifier>> => {
  const url = `${baseURL}/api/verifiers`
  var { status, data } = await clinet.get(url)
  if (status != 200) return Results.fail()
  return Results.ok(data)
}
